import { useQuery, useMutation } from "@tanstack/react-query";
import { useAssistantActions } from "../services/api/assistant-service";

export const useAssistant = () => {
  const assistantActions = useAssistantActions();

  const {
    isLoading: isMessagesLoading,
    error: messagesError,
    data: assistantMessages,
  } = useQuery({
    queryKey: ["messages"],
    queryFn: assistantActions.getAssistantMessages,
  });

  const askQuestion = useMutation({
    mutationFn: ({ question }) => {
      console.log(`asking question: ${question}`);
      const answer = assistantActions.askQuestion({ question });
      return answer;
    },
    onSuccess: () => {
      console.log("asked question successfully");
    },
  });

  const updateAssistant = useMutation({
    mutationFn: () => {
      const response = assistantActions.updateAssistant();
      return response;
    },
    onSuccess: () => {
      console.log("assistant updated");
    },
  });

  return {
    isMessagesLoading,
    assistantMessages,
    messagesError,
    askQuestion,
    updateAssistant,
  };
};
