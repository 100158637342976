import React from "react";
import styles from "./tab-nav.module.scss";

export const TabNav = ({ children, activeTab, setActiveTab }) => {
  const cloneChildWithProps = (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { activeTab, setActiveTab });
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    return cloneChildWithProps(child);
  });

  return <ul className={styles.nav}>{childrenWithProps}</ul>;
};

export const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li onClick={handleClick} className={activeTab === id ? styles.active : ""}>
      {title}
    </li>
  );
};
