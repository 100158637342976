import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import { usePersons } from "../../hooks/use-persons";
import { useTeams } from "../../hooks/use-teams";

const PersonEdit = () => {
  const [teamId, setTeamId] = useState("");

  let { personId } = useParams();

  const { isTeamsLoading, teams } = useTeams({});

  const { isPersonLoading, personError, person, updatePerson } = usePersons({
    personId,
  });

  useEffect(() => {
    if (person) {
      setValue("firstName", person.firstName);
      setValue("lastName", person.lastName);
      setValue("email", person.email);
      setTeamId(person.teamId);
    }
  }, [person]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const email = watch("email");

  const onSubmit = (e) => {
    console.log(
      `Updating person id:${person.id} with first:${firstName}, last:${lastName}, email${email}, teamId:${teamId}`
    );

    updatePerson.mutate({
      id: person.id,
      firstName,
      lastName,
      email,
      teamId,
    });
    navigate("/home/persons");
  };

  if (isPersonLoading || isTeamsLoading) {
    return <article aria-busy="true"></article>;
  }

  if (personError) {
    return <div>Error: {personError.message}</div>;
  }

  return (
    <div className="container" data-testid="person-edit">
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit();
        })}
      >
        <div>
          <label htmlFor="first-name">
            First
            <input
              {...register("firstName", {
                required: "First name is required",
              })}
              className={errors?.firstName?.message ? "error" : "no-error"}
              defaultValue={person.firstName}
              id="first-name"
              placeholder="First Name"
            />
          </label>
          <p className="error">{errors.firstName?.message}</p>

          <label htmlFor="last-name">
            Last
            <input
              {...register("lastName", {
                required: "Last name is required",
              })}
              className={errors?.lastName?.message ? "error" : "no-error"}
              defaultValue={person.lastName}
              id="last-name"
              placeholder="Last Name"
            />
          </label>
          <p className="error">{errors.lastName?.message}</p>

          <label htmlFor="email">
            Email
            <input
              {...register("email", {
                pattern: {
                  value: /^[+\w0-9._-]+@[\w0-9._-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
              className={errors?.email?.message ? "error" : "no-error"}
              id="email"
              placeholder="Email"
            />
          </label>
          <p className="error">{errors.email?.message}</p>

          <select
            value={person.teamId}
            onChange={(e) => {
              console.log("changing team");
              setTeamId(e.target.value);
            }}
          >
            {teams.map((team) => {
              return (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              );
            })}
          </select>
        </div>

        <input type="submit" value="Save" />
      </form>
      <Link onClick={() => navigate(-1)} data-testid="cancel-button">
        Cancel
      </Link>
    </div>
  );
};

export default PersonEdit;
