import { useNavigate } from "react-router-dom";
import axiosInstance from "../services/api/api";
import { useUserActions } from "../services/api/user-service";

export const useApi = () => {
  const navigate = useNavigate();
  const userActions = useUserActions();

  // Set up the interceptors
  axiosInstance.interceptors.request.use((config) => {
    config.headers = authHeader();
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      const unauthorizedStatusCodes = [400, 401, 403];

      if (unauthorizedStatusCodes.includes(status)) {
        console.log("unauthorized, logging out...");
        userActions.logout();
        navigate("/login");
      }

      return Promise.reject(error);
    }
  );

  // axiosInstance.interceptors.request.use((request) => {
  //   console.log("Starting Request", request.headers);
  //   return request;
  // });

  // axiosInstance.interceptors.response.use((response) => {
  //   console.log("Response:", response.headers);
  //   return response;
  // });
};

function authHeader() {
  let user;
  try {
    user = JSON.parse(localStorage.getItem("loggedInUser"));
  } catch (error) {
    console.error("Error parsing user from localStorage", error);
    user = null;
  }

  if (user && user.token) {
    return { Authorization: `Bearer ${user.token}` };
  } else {
    return {};
  }
}
