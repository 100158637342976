import ConfettiExplosion from "react-confetti-explosion";
import { useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useUserActions } from "../../services/api/user-service";
import { isOnboardingAtom } from "../../services/state/state";
import { onboardingAtom } from "../../services/state/state";
import Actions from "../multi-action/actions";
import { useTeams } from "../../hooks/use-teams";

const OnboardingStep3 = () => {
  const setIsOnboarding = useSetRecoilState(isOnboardingAtom);
  const onboarding = useRecoilValue(onboardingAtom);
  const [onboardingComplete, setOnboardingComplete] = useState(false);

  const userActions = useUserActions();

  const { createTeam } = useTeams({});

  const actions = [];

  if (onboarding.teamName) {
    actions.push({
      title: "Creating team...",
      action: () => {
        console.log("Creating team...");
        return createTeam.mutateAsync({ name: onboarding.teamName });
      },
    });
  }

  if (onboarding.inviteCode) {
    actions.push({
      title: "Joining team...",
      action: () => {
        console.log(`Joining team with invite code: ${onboarding.inviteCode}`);
        return userActions
          .join(onboarding.inviteCode)
          .then(() => {
            console.log("Joined team");
          })
          .catch((error) => {
            console.error("Error joining team", error);
          });
      },
    });
  }

  return (
    <div data-testid="onboarding-step3">
      {onboardingComplete ? (
        <>
          <ConfettiExplosion duration={4000} />
          <h1>You're all set up.</h1>
          <div>
            <button onClick={() => setIsOnboarding(false)}>
              Go to your dashboard
            </button>
          </div>
        </>
      ) : (
        <Actions
          actions={actions}
          complete={() => {
            setOnboardingComplete(true);
          }}
        />
      )}
    </div>
  );
};

export default OnboardingStep3;
