import { useContext } from "react";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import SearchContext from "../../context/search-context";
import Search from "../search/search";
import styles from "./notes-header.module.scss";

const NotesHeader = ({ onSort }) => {
  const onSearch = useContext(SearchContext);

  return (
    <div className={styles.notesHeader} data-testid="notes-header">
      <header>Notes</header>
      <div className={styles.noteControls}>
        <div className={styles.search}>
          <Search placeholder="Search notes" onSearch={onSearch} />
        </div>
        <div className={styles.sortControls}>
          <BsSortUp
            className={styles.sortControl}
            onClick={() => onSort(true)}
            size="32"
            data-testid="sort-up"
          />
          <BsSortDown
            className={styles.sortControl}
            onClick={() => onSort(false)}
            size="32"
            data-testid="sort-down"
          />
        </div>
      </div>
    </div>
  );
};

export default NotesHeader;
