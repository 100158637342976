import { useState } from "react";
import { PiTrashLight } from "react-icons/pi";
import styles from "./todo.module.scss";

const Todo = ({ todo, onEdit, onDelete }) => {
  const [title, setTitle] = useState(todo.title);
  const [completed, setCompleted] = useState(todo.completed);

  const handleDelete = () => {
    onDelete(todo.id);
  };

  const handleEdit = () => {
    onEdit(todo.id, title, todo.note, todo.completed);
  };

  const handleInputChange = (event) => {
    setTitle(event.target.value);
  };

  return (
    <div className={styles.todo}>
      <input
        type="checkbox"
        className={styles.todoComplete}
        onChange={() => {
          setCompleted(!completed);
          onEdit(todo.id, title, todo.note, !completed);
        }}
        defaultChecked={todo.completed}
        data-testid="todo-complete"
      />
      <input
        className={styles.todoTitle}
        defaultValue={todo.title}
        onChange={handleInputChange}
        onBlur={handleEdit}
        data-testid="todo-title"
      />
      <PiTrashLight
        onClick={handleDelete}
        className={styles.todoDeleteButton}
        size="32"
        data-testid="delete-button"
      />
    </div>
  );
};

export default Todo;
