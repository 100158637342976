import Note from "./note";
import styles from "./notes.module.scss";

const Notes = ({ notes, selectedNote, onCreate, onSelect }) => {
  const createNewNote = () => {
    onCreate();
  };

  const handleNoteSelection = (note) => {
    console.log(`selected note with id ${note.id} and note ${note.text}`);
    onSelect(note);
  };

  const getNoteSelectionStyle = (noteId) => {
    if (selectedNote && selectedNote.id === noteId) {
      return "selected";
    } else {
      return "";
    }
  };

  const renderNotes = () => {
    return notes.map((note) => {
      return (
        <li
          key={note.id}
          className={`${styles.note} ${
            getNoteSelectionStyle(note.id) ? styles.selected : ""
          }`}
          data-testid={selectedNote?.id === note.id ? "note-selected" : ""}
        >
          <Note onClick={handleNoteSelection} note={note} />
        </li>
      );
    });
  };

  return (
    <>
      <div className={styles.notes}>
        <ul>{renderNotes()}</ul>
      </div>
      <button data-testid="create-button" onClick={() => createNewNote()}>
        New Note
      </button>
    </>
  );
};

export default Notes;
