import { useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import styles from "./todo-create.module.scss";

const TodoCreate = ({ onCreate }) => {
  const [title, setTitle] = useState("");

  const newTodo = () => {
    console.log(`Creating new todo: ${title}`);
    onCreate(title);
    setTitle("");
  };

  const handleInputChange = (event) => {
    setTitle(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // prevent form submission
      newTodo();
    }
  };

  return (
    <div className={styles.todoCreate}>
      <input
        placeholder="What needs to be done?"
        value={title}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        data-testid="todo-input"
      />
      <MdAddCircleOutline
        onClick={newTodo}
        className={styles.todoCreateButton}
        size="32"
      />
    </div>
  );
};

export default TodoCreate;
