import Todos from "./todos";
import { useTodos } from "../../hooks/use-todos";
import TodoCreate from "./todo-create";
import styles from "./todos-dashboard.module.scss";

const TodosDashboard = () => {
  const { isPending, error, todos, createTodo, updateTodo, deleteTodo } =
    useTodos();

  const onTodoCreate = (title, note, completed) => {
    createTodo.mutate(title, note, completed);
  };

  const onTodoEdit = async (id, title, note, completed) => {
    updateTodo.mutate({ id, title, note, completed });
  };

  const onTodoDelete = async (id) => {
    deleteTodo.mutate(id);
  };

  if (isPending) {
    return <article aria-busy="true" data-testid="pending"></article>;
  }

  if (error) {
    return <div data-testid="error">Error: {error.message}</div>;
  }

  return (
    <div className={styles.todosContainer} data-testid="todos-container">
      <header>Action Items</header>
      <Todos todos={todos} onEdit={onTodoEdit} onDelete={onTodoDelete} />
      <TodoCreate onCreate={onTodoCreate} />
    </div>
  );
};

export default TodosDashboard;
