import { Link } from "react-router-dom";
import { usePersons } from "../../hooks/use-persons";

function Persons() {
  const {
    isPersonsLoading,
    personsError,
    persons,
    deletePerson,
    invitePerson,
  } = usePersons({});

  const handleDelete = async (personId) => {
    deletePerson.mutate(personId);
  };

  const handleInvite = async (person) => {
    console.log(`inviting person with id ${person.id}`);
    invitePerson.mutate({
      email: person.email,
      personId: person.id,
      teamId: person.teamId,
    });
  };

  const renderInvite = (person) => {
    console.log(`person email: ${person.email}, status: ${person.status}`);
    return person.email ? (
      person.status === "joined" ? (
        " (joined)"
      ) : person.status === "invited" ? (
        " (invite pending)"
      ) : (
        <>
          {" ("}
          <Link onClick={() => handleInvite(person)}>invite</Link>
          {")"}
        </>
      )
    ) : (
      ""
    );
  };

  const renderTable = () => {
    return persons.map((person) => {
      return (
        <tr key={person.id} data-testid="person">
          <td>{person.firstName}</td>
          <td>{person.lastName}</td>
          <td>
            {person.email}&nbsp;{renderInvite(person)}
          </td>
          <td>
            <Link to={`${person.id}/edit`}>Edit</Link>
            <Link onClick={() => handleDelete(person.id)}>Delete</Link>
          </td>
        </tr>
      );
    });
  };

  if (isPersonsLoading) {
    return <article aria-busy="true"></article>;
  }

  if (personsError) {
    return <div>{personsError.message}</div>;
  }

  return (
    <div data-testid="persons">
      <article>
        <header>Manage Persons</header>
        <table role="grid" id="persons">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </table>
        <footer>
          <Link to="create">Add New (+)</Link>
        </footer>
      </article>
    </div>
  );
}
export default Persons;
