import { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { onboardingAtom } from "../../services/state/state";
import styles from "./onboarding-step.module.scss";

const OnboardingStep2 = ({ handlePrevious, handleNext }) => {
  const [inviteCode, setInviteCode] = useState("");
  const [onboarding, setOnboarding] = useRecoilState(onboardingAtom);

  const handleTeamJoin = () => {
    setOnboarding({ ...onboarding, inviteCode });
    handleNext();
  };

  const handleInputChange = (event) => {
    setInviteCode(event.target.value);
  };

  return (
    <div data-testid="onboarding-step2">
      <div>
        Do you have an invite code to join a team? If so, enter it here.
      </div>
      <div className={styles.submitInvite}>
        <input
          placeholder="invite code"
          value={inviteCode}
          onChange={handleInputChange}
        />
        <button onClick={handleTeamJoin}>Join</button>
      </div>
      <div className={styles.onboardingFooter}>
        <Link onClick={handlePrevious}>Back</Link>
        <Link onClick={handleNext}>Next</Link>
      </div>
    </div>
  );
};

export default OnboardingStep2;
