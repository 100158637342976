import { API_URL } from "../../constants";
import axiosInstance from "./api";

function useTodoActions() {
  const createTodo = async (title, note, completed, personId) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/todos`, {
        title: title,
        note: note,
        completed: completed,
        person_id: personId,
      });
      return response.data;
    } catch (error) {
      console.error("Error creating todo", error);
      throw error;
    }
  };

  const retrieveTodos = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/todos`);
      return response.data;
    } catch (error) {
      console.error("Error retrieving todos", error);
      throw error;
    }
  };

  const retrievePersonTodos = async (personId) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/persons/${personId}/todos`
      );
      return response.data;
    } catch (error) {
      console.error(`Error retrieving todos for person with id ${personId}`);
      throw error;
    }
  };

  const retrieveTodo = async (id) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/todos/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error retrieving todo with id ${id}`, error);
      throw error;
    }
  };

  const updateTodo = async (id, title, note, completed, personId) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/todos/${id}`, {
        title: title,
        note: note,
        completed: completed,
        person_id: personId,
      });
      return response.data;
    } catch (error) {
      console.error(`Error updating todo with id ${id}`, error);
      throw error;
    }
  };

  const deleteTodo = async (id) => {
    try {
      await axiosInstance.delete(`${API_URL}/todos/${id}`);
    } catch (error) {
      console.error(`Error deleting todo with id ${id}`, error);
      throw error;
    }
  };

  return {
    createTodo,
    retrieveTodos,
    retrievePersonTodos,
    retrieveTodo,
    updateTodo,
    deleteTodo,
  };
}

export { useTodoActions };
