import AgendaItem from "./agenda-item";
import styles from "./agenda-items.module.scss";

const AgendaItems = ({ notes }) => {
  const renderNotes = () => {
    return notes.map((note) => {
      return (
        <li key={note.id}>
          <AgendaItem note={note} data-testid="agenda-item" />
        </li>
      );
    });
  };

  return (
    <div className={styles.agendaItems}>
      <ul>{renderNotes()}</ul>
    </div>
  );
};

export default AgendaItems;
