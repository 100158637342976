import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  UNDO_COMMAND,
  REDO_COMMAND,
} from "lexical";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { mergeRegister } from "@lexical/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faStrikethrough,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
  faRotateLeft,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";

import "./editor.scss";

export const GuruEditor = ({
  defaultValue,
  placeholder,
  onTextChanged,
  readOnly,
}) => {
  const onChange = (editorState) => {
    editorState.read(() => {
      const root = $getRoot();
      // console.log(`text content: ${root.getTextContent()}`);
      const selection = $getSelection();
      //console.log(`selection: ${selection}`);
      const editorStateJson = JSON.stringify(editorState.toJSON());
      //console.log(`editor state json: ${editorStateJson}`);
      onTextChanged(root.getTextContent(), editorStateJson);
    });
  };

  console.log(`note is editable: ${readOnly === false}`);
  return (
    <LexicalComposer
      initialConfig={{
        theme: {
          paragraph: "paragraph",
          rtl: "text-right",
          ltr: "text-left",
          text: {
            bold: "bold",
            italic: "italic",
            underline: "underline",
            strikethrough: "strikethrough",
          },
        },
        onError(error) {
          throw error;
        },
      }}
    >
      <div>
        <Toolbar />
        <RichTextPlugin
          contentEditable={<ContentEditable className="guru-editor" />}
          placeholder={
            <div className="guru-editor-placeholder">{placeholder}</div>
          }
        />
      </div>
      <OnChangePlugin onChange={(editorState) => onChange(editorState)} />
      <HistoryPlugin />
      <BottomBar defaultValue={defaultValue} readOnly={readOnly} />
    </LexicalComposer>
  );
};

const Toolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = React.useState(false);
  const [isItalic, setIsItalic] = React.useState(false);
  const [isStrikethrough, setIsStrikethrough] = React.useState(false);
  const [isUnderline, setIsUnderline] = React.useState(false);

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsUnderline(selection.hasFormat("underline"));
    }
  }, []);

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      })
    );
  }, [updateToolbar, editor]);

  return (
    <div className="guru-editor-toolbar">
      <button
        className={clsx(
          "guru-editor-toolbar-button",
          isBold
            ? "guru-editor-toolbar-button-bgColor"
            : "guru-editor-toolbar-button-bgTransparent"
        )}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
      >
        <FontAwesomeIcon icon={faBold} className="text-toolbar-icon" />
      </button>
      <button
        className={clsx(
          "guru-editor-toolbar-button",
          isStrikethrough
            ? "guru-editor-toolbar-button-bgColor"
            : "guru-editor-toolbar-button-bgTransparent"
        )}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        }}
      >
        <FontAwesomeIcon icon={faStrikethrough} className="text-toolbar-icon" />
      </button>
      <button
        className={clsx(
          "guru-editor-toolbar-button",
          isItalic
            ? "guru-editor-toolbar-button-bgColor"
            : "guru-editor-toolbar-button-bgTransparent"
        )}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
      >
        <FontAwesomeIcon icon={faItalic} className="text-toolbar-icon" />
      </button>
      <button
        className={clsx(
          "guru-editor-toolbar-button",
          isUnderline
            ? "guru-editor-toolbar-button-bgColor"
            : "guru-editor-toolbar-button-bgTransparent"
        )}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
      >
        <FontAwesomeIcon icon={faUnderline} className="text-toolbar-icon" />
      </button>

      {/* <span className="w-[1px] bg-gray-600 block h-full"></span> */}
      <span className="toolbar-bar"></span>

      <button
        className={clsx("guru-editor-toolbar-button")}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        }}
      >
        <FontAwesomeIcon icon={faAlignLeft} className="text-toolbar-icon" />
      </button>
      <button
        className={clsx("guru-editor-toolbar-button")}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        }}
      >
        <FontAwesomeIcon icon={faAlignCenter} className="text-toolbar-icon" />
      </button>
      <button
        className={clsx("guru-editor-toolbar-button")}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        }}
      >
        <FontAwesomeIcon icon={faAlignRight} className="text-toolbar-icon" />
      </button>
      <button
        className={clsx("guru-editor-toolbar-button")}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        }}
      >
        <FontAwesomeIcon icon={faAlignJustify} className="text-toolbar-icon" />
      </button>

      {/* <span className="w-[1px] bg-gray-600 block h-full"></span> */}
      <span className="toolbar-bar"></span>

      <button
        className={clsx("guru-editor-toolbar-button")}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(UNDO_COMMAND);
        }}
      >
        <FontAwesomeIcon icon={faRotateLeft} className="text-toolbar-icon" />
      </button>
      <button
        className={clsx("guru-editor-toolbar-button")}
        onClick={(event) => {
          event.preventDefault();
          editor.dispatchCommand(REDO_COMMAND);
        }}
      >
        <FontAwesomeIcon icon={faRotateRight} className="text-toolbar-icon" />
      </button>
    </div>
  );
};

const BottomBar = ({ defaultValue, readOnly }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (
      defaultValue === null ||
      defaultValue === undefined ||
      defaultValue === ""
    ) {
      console.log("setting default value to empty string");
      defaultValue = `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;
    }
    const editorState = editor.parseEditorState(defaultValue);
    editor.setEditorState(editorState);
    // Don't focus the editor when the effect fires because we're giving focus to the title input
    //editor.focus();

    editor.setEditable(readOnly === false);
  }, [editor, defaultValue, readOnly]);

  return null;
};

export default GuruEditor;
