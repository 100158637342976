import { useState } from "react";
import { useNotes } from "../../hooks/use-notes";
import { Tabs, TabNav, TabOutlet, TabContent, TabNavItem } from "../tab";
import AgendaContext from "../../context/agenda-context";
import AgendaItems from "./agenda-items";
import styles from "./agenda.module.scss";

const Agenda = ({ personId }) => {
  const [activeTab, setActiveTab] = useState("tab-active");

  const { isNoteTypesLoading, isNotesLoading, notesError, notes, updateNote } =
    useNotes({ personId: personId, filter: { isAgendaItem: true } });

  const onNoteUpdate = (note) => {
    updateNote.mutateAsync(note);
  };

  if (notesError) {
    return <div data-testid="error">Error...</div>;
  }

  if (isNoteTypesLoading || isNotesLoading) {
    return <div data-testid="loading">Loading...</div>;
  }

  const tenMinutesAgo = new Date(Date.now() - 10 * 60 * 1000);

  return (
    <div className={styles.agendaContainer} data-testid="agenda">
      <div className={styles.agendaHeader}>Agenda</div>
      <div className={styles.agendaBody}>
        <Tabs id="tabs-agenda">
          <TabNav activeTab={activeTab} setActiveTab={setActiveTab}>
            <TabNavItem title="Active" id="tab-active" />
            <TabNavItem title="Archived" id="tab-archived" />
          </TabNav>
          <TabOutlet>
            <TabContent id="tab-active" activeTab={activeTab}>
              <AgendaContext.Provider value={onNoteUpdate}>
                {/* show notes that are not archived or were archived less than
                10 minutes ago */}
                <AgendaItems
                  notes={notes.filter(
                    (note) =>
                      note.isArchived === false ||
                      (note.isArchived === true &&
                        new Date(note.modifiedAt) > tenMinutesAgo)
                  )}
                />
              </AgendaContext.Provider>
            </TabContent>
            <TabContent id="tab-archived" activeTab={activeTab}>
              <AgendaContext.Provider value={onNoteUpdate}>
                <AgendaItems
                  notes={notes.filter((note) => note.isArchived === true)}
                />
              </AgendaContext.Provider>
            </TabContent>
          </TabOutlet>
        </Tabs>
      </div>
    </div>
  );
};

export default Agenda;
