import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isOnboardingAtom } from "./services/state/state";
import { useApi } from "./hooks/use-api";
import MainNav from "./components/main-nav/main-nav";
import SideNav from "./components/side-nav/side-nav";
import Onboarding from "./components/onboarding/onboarding";
import Assistant from "./components/assistant/assistant";
import { useTeams } from "./hooks/use-teams";
import styles from "./App.module.scss";

const App = () => {
  const [showAssistant, setShowAssistant] = useState(false);

  const toggleAssistant = () => {
    setShowAssistant((prevShowAssistant) => !prevShowAssistant);
  };

  // Set up axios interceptors
  useApi();

  const isOnboarding = useRecoilValue(isOnboardingAtom);

  const { isTeamsLoading, teams } = useTeams({});

  if (isTeamsLoading) {
    return <article aria-busy="true"></article>;
  }

  if (isOnboarding || teams.length === 0) {
    return (
      <div className={styles.app}>
        <MainNav />
        <div className={`${styles.mainArea} ${styles.onboarding}`}>
          <Onboarding />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.app}>
      <div className={styles.mainNav}>
        <MainNav />
      </div>
      <div className={styles.content}>
        <div className={styles.sideNav}>
          <SideNav onToggleAssistant={toggleAssistant} />
        </div>
        {showAssistant && (
          <div className={styles.assistantView}>
            <Assistant />
          </div>
        )}
        <main
          className={`${styles.mainArea} ${
            showAssistant ? styles.slideRight : ""
          }`}
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default App;
