import { useState } from "react";
import Action from "./action";
import styles from "./actions.module.scss";

const Actions = ({ actions, complete }) => {
  const [stepToComplete, setStepToComplete] = useState(0);
  const doneWithStep = (step) => {
    console.log(`Step ${step} is complete!`);
    if (step === actions.length - 1) {
      console.log("All steps are complete!");
      complete();
    }
    setStepToComplete(step + 1);
  };

  const renderActions = () => {
    return actions.map((action, index) => {
      return (
        <li key={index} data-testid="action">
          <Action
            step={index}
            title={action.title}
            action={action.action}
            start={index === stepToComplete}
            completed={stepToComplete > index}
            stepComplete={doneWithStep}
            minExecutionTime={action.minExecutionTime}
          />
        </li>
      );
    });
  };

  return (
    <ul className={styles.multiAction} data-testid="actions">
      {renderActions()}
    </ul>
  );
};

export default Actions;
