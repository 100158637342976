import { API_URL } from "../../constants";
import axiosInstance from "./api";
import { Meeting } from "../../models/meeting";

export { useMeetingActions };

function useMeetingActions() {
  const createMeeting = async (meeting) => {
    console.log(
      `creating meeting with text: ${meeting.text}, date: ${meeting.date}`
    );
    try {
      const response = await axiosInstance.post(`${API_URL}/meetings`, {
        text: meeting.text,
        date: meeting.date,
        access_type: meeting.accessType,
        person_id: meeting.personId,
      });

      return response.data;
    } catch (error) {
      console.error("Error creating meeting", error);
      throw error;
    }
  };

  const retrievePersonMeetings = async (personId, filter) => {
    const params = {};
    if (filter) {
      if (filter.hasOwnProperty("isShared")) {
        params.is_shared = filter.isShared;
      }
    }
    try {
      const response = await axiosInstance.get(
        `${API_URL}/persons/${personId}/meetings`,
        {
          params: params,
        }
      );

      if (!response.data) {
        return [];
      }

      const meetings = response.data.map((meeting) => {
        return new Meeting({
          id: meeting.id,
          ownerId: meeting.owner_id,
          title: meeting.title,
          text: meeting.text,
          richText: meeting.rich_text,
          personId: meeting.person_id,
          accessType: meeting.access_type,
          date: meeting.date,
          createdAt: meeting.created_at,
          modifiedAt: meeting.modified_at,
        });
      });

      return meetings;
    } catch (error) {
      console.error("Error retrieving meetings", error);
      throw error;
    }
  };

  const retrieveMeeting = async (meetingId) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/meetings/${meetingId}`
      );

      return new Meeting({
        id: response.data.id,
        ownerId: response.data.owner_id,
        title: response.data.title,
        text: response.data.text,
        richText: response.data.rich_text,
        personId: response.data.person_id,
        accessType: response.data.access_type,
        date: response.data.date,
        createdAt: response.data.created_at,
        modifiedAt: response.data.modified_at,
      });
    } catch (error) {
      console.error("Error retrieving meeting", error);
      throw error;
    }
  };

  const updateMeeting = async (meeting) => {
    console.log(
      `updating meeting with id ${meeting.id}, text: ${meeting.text}, date: ${meeting.date}, access type: ${meeting.accessType}`
    );
    try {
      const response = await axiosInstance.put(
        `${API_URL}/meetings/${meeting.id}`,
        {
          text: meeting.text,
          rich_text: meeting.richText,
          date: meeting.date,
          person_id: meeting.personId,
          access_type: meeting.accessType,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error updating meeting", error);
      throw error;
    }
  };

  const deleteMeeting = async (meetingId) => {
    console.log(`deleting meeting with id ${meetingId}`);
    try {
      const response = await axiosInstance.delete(
        `${API_URL}/meetings/${meetingId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting meeting", error);
      throw error;
    }
  };

  return {
    createMeeting,
    retrievePersonMeetings,
    retrieveMeeting,
    updateMeeting,
    deleteMeeting,
  };
}
