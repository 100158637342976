import { API_URL } from "../../constants";
import axiosInstance from "./api";

export { useAssistantActions };

function useAssistantActions() {
  const askQuestion = async ({ question }) => {
    const data = new FormData();
    data.append("question", question);
    try {
      const response = await axiosInstance.post(
        `${API_URL}/assistants/questions`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(`answer: ${response.data}`);
      return response.data;
    } catch (error) {
      console.error("Error asking assistant", error);
      throw error;
    }
  };

  const getAssistantMessages = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/assistants/messages`
      );

      return response.data;
    } catch (error) {
      console.error("Error getting assistant messages", error);
      throw error;
    }
  };

  const updateAssistant = async () => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/assistants/files`,
        {}
      );

      return response.data;
    } catch (error) {
      console.error("Error updating assistant", error);
      throw error;
    }
  };

  return {
    getAssistantMessages,
    updateAssistant,
    askQuestion,
  };
}
