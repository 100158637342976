import { API_URL } from "../../constants";
import axiosInstance from "./api";
import { Note } from "../../models/note";

export { useNoteActions };

function useNoteActions() {
  const createNote = async (note) => {
    console.log(
      `creating note with title: ${note.title}, text: ${note.text}, agendaItem: ${note.isAgendaItem}, accessType Name: ${note.accessType.name}, accessType Value: ${note.accessType.value}, person_id: ${note.personId}`
    );
    try {
      const response = await axiosInstance.post(`${API_URL}/notes`, {
        title: note.title,
        text: note.text,
        rich_text: note.richText,
        note_types: note.noteTypes,
        is_agenda_item: note.isAgendaItem,
        is_archived: note.isArchived,
        access_type: note.accessType,
        person_id: note.personId,
      });

      return response.data;
    } catch (error) {
      console.error("Error creating note", error);
      throw error;
    }
  };

  const retrievePersonNotes = async (personId, filter, sort) => {
    const params = {};
    if (filter) {
      if (filter.hasOwnProperty("isAgendaItem")) {
        params.is_agenda_item = filter.isAgendaItem;
      }
      if (filter.hasOwnProperty("isShared")) {
        params.is_shared = filter.isShared;
      }
      if (filter.hasOwnProperty("isArchived")) {
        params.is_archived = filter.isArchived;
      }
    }
    if (sort) {
      if (sort.hasOwnProperty("dateCreated")) {
        params.sort =
          sort.dateCreated === "asc" ? "+created_at" : "-created_at";
      }
    }
    try {
      const response = await axiosInstance.get(
        `${API_URL}/persons/${personId}/notes`,
        {
          params: params,
        }
      );

      const notes = response.data.map((note) => {
        return new Note({
          id: note.id,
          ownerId: note.owner_id,
          title: note.title,
          text: note.text,
          richText: note.rich_text,
          noteTypes: note.note_types,
          isAgendaItem: note.is_agenda_item,
          isArchived: note.is_archived,
          accessType: note.access_type,
          personId: note.person_id,
          createdAt: note.created_at,
          modifiedAt: note.modified_at,
        });
      });
      return notes;
    } catch (error) {
      console.error(`Error retrieving notes for person with id ${personId}`);
      throw error;
    }
  };

  const retrieveNote = async (id) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/notes/${id}`);
      const note = new Note({
        id: response.data.id,
        title: response.data.title,
        text: response.data.text,
        richText: response.data.rich_text,
        noteTypes: response.data.note_types,
        isAgendaItem: response.data.is_agenda_item,
        isArchived: response.data.is_archived,
        accessType: response.data.access_type,
        personId: response.data.person_id,
        createdAt: response.data.created_at,
        modifiedAt: response.data.modified_at,
      });
      return note;
    } catch (error) {
      console.error(`Error retrieving note with id ${id}`, error);
      throw error;
    }
  };

  const updateNote = async (note) => {
    console.log(
      `updating note with id: ${note.id}, title: ${note.title}, text: ${note.text}, access type name: ${note.accessType.name}, access type value: ${note.accessType.value}, agendaItem: ${note.isAgendaItem}, archived: ${note.isArchived}, person_id: ${note.personId}`
    );
    try {
      const response = await axiosInstance.put(`${API_URL}/notes/${note.id}`, {
        title: note.title,
        text: note.text,
        rich_text: note.richText,
        note_types: note.noteTypes,
        is_agenda_item: note.isAgendaItem,
        is_archived: note.isArchived,
        access_type: note.accessType,
        person_id: note.personId,
      });

      return response.data;
    } catch (error) {
      console.error(`Error updating note with id ${note.id}`, error);
      throw error;
    }
  };

  const deleteNote = async (id) => {
    try {
      const response = await axiosInstance.delete(`${API_URL}/notes/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting note with id ${id}`, error);
      throw error;
    }
  };

  const retrieveNoteTypes = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/notes/note_types`);
      return response.data;
    } catch (error) {
      console.error("Error retrieving note types", error);
      throw error;
    }
  };

  return {
    retrieveNoteTypes,
    createNote,
    retrievePersonNotes,
    retrieveNote,
    updateNote,
    deleteNote,
  };
}
