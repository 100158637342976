import { useRecoilValue } from "recoil";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { currentPersonIDAtom } from "../services/state/state";
import { useTodoActions } from "../services/api/todo-service";

export const useTodos = () => {
  const todoActions = useTodoActions();
  const currentPersonID = useRecoilValue(currentPersonIDAtom);
  const queryClient = useQueryClient();

  const {
    isPending,
    error,
    data: todos,
  } = useQuery({
    queryKey: ["todos", currentPersonID],
    queryFn: () => todoActions.retrievePersonTodos(currentPersonID),
  });

  const createTodo = useMutation({
    mutationFn: (title, note, completed) =>
      todoActions.createTodo(title, note, completed, currentPersonID),
    onSuccess: () => {
      console.log("created todo successfully");
      queryClient.invalidateQueries({ queryKey: ["todos", currentPersonID] });
    },
  });

  const updateTodo = useMutation({
    mutationFn: ({ id, title, note, completed }) =>
      todoActions.updateTodo(id, title, note, completed, currentPersonID),
    onSuccess: () => {
      console.log("updated todo successfully");
      queryClient.invalidateQueries({ queryKey: ["todos", currentPersonID] });
    },
  });

  const deleteTodo = useMutation({
    mutationFn: (id) => todoActions.deleteTodo(id),
    onSuccess: () => {
      console.log("deleted todo successfully");
      queryClient.invalidateQueries({ queryKey: ["todos", currentPersonID] });
    },
  });

  return {
    isPending,
    error,
    todos,
    createTodo,
    updateTodo,
    deleteTodo,
  };
};
