import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { usePersonActions } from "../services/api/person-service";

export const usePersons = ({ personId }) => {
  const personActions = usePersonActions();
  const queryClient = useQueryClient();

  const {
    isLoading: isPersonsLoading,
    error: personsError,
    data: persons,
  } = useQuery({
    queryKey: ["persons"],
    queryFn: personActions.retrievePersons,
    enabled: !personId,
  });

  const {
    isLoading: isPersonLoading,
    error: personError,
    data: person,
  } = useQuery({
    queryKey: ["person", personId],
    queryFn: () => {
      return personActions.retrievePerson(personId);
    },
    enabled: !!personId,
  });

  const createPerson = useMutation({
    mutationFn: ({ firstName, lastName, email, teamId }) => {
      console.log(
        `creating person with ${firstName}, ${lastName}, ${email}, ${teamId}`
      );
      return personActions.createPerson({ firstName, lastName, email, teamId });
    },
    onSuccess: () => {
      console.log("created person successfully");
      queryClient.refetchQueries({ queryKey: ["persons"] });
    },
  });

  const updatePerson = useMutation({
    mutationFn: ({ id, firstName, lastName, email, teamId }) => {
      console.log(
        `updating person with ${id}, ${firstName}, ${lastName}, ${email}, ${teamId}`
      );
      return personActions.updatePerson({
        id,
        firstName,
        lastName,
        email,
        teamId,
      });
    },
    onSuccess: () => {
      console.log("updated person successfully");
      queryClient.invalidateQueries("persons");
    },
  });

  const deletePerson = useMutation({
    mutationFn: (id) => personActions.deletePerson(id),
    onSuccess: () => {
      console.log("deleted person successfully");
      queryClient.invalidateQueries("persons");
    },
  });

  const invitePerson = useMutation({
    mutationFn: ({ email, personId, teamId }) => {
      console.log(`use-persons: inviting person with id ${personId}`);
      personActions.invitePerson(email, personId, teamId);
    },
    onSuccess: () => {
      console.log(
        "invited person successfully.  Sleeping for 1 second before invalidating."
      );
      // Simulate a delay before invalidating the query. This is to allow the server to persist the new
      // invite status before we refresh the UI (I'm sure there's a better solution here).
      setTimeout(() => {
        console.log("done sleeping.  Invalidating persons query.");
        queryClient.invalidateQueries("persons");
      }, 1000);
    },
  });

  return {
    isPersonsLoading,
    personsError,
    persons,
    isPersonLoading,
    personError,
    person,
    createPerson,
    updatePerson,
    deletePerson,
    invitePerson,
  };
};
