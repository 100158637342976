import { formatDate } from "../../common/date-utils";
import styles from "./meeting.module.scss";

const Meeting = ({ meeting, selected, onMeetingSelect }) => {
  const getMeetingActiveStyle = (meeting) => {
    if (selected) {
      return "active";
    } else {
      return "";
    }
  };

  return (
    <div
      className={`${styles.meetingItem} ${
        getMeetingActiveStyle(meeting) ? styles.active : ""
      }`}
    >
      <div
        className={styles.meetingContent}
        onClick={() => {
          onMeetingSelect(meeting);
        }}
        data-testid="meeting-content"
      >
        <div>{`Date: ${formatDate(meeting.date, "long")}`}</div>
        <div>{meeting.text}</div>
        <div>
          <small>
            {`Created: ${formatDate(
              meeting.createdAt
            )}, Last Updated: ${formatDate(meeting.modifiedAt)}`}
          </small>
        </div>
      </div>
    </div>
  );
};

export default Meeting;
