import styles from "./access-type-selector.module.scss";

const AccessTypeSelector = ({
  accessTypes,
  selectedAccessType,
  onAccessTypeSelect,
  editable,
}) => {
  return (
    <select
      className={styles.accessTypeSelector}
      value={selectedAccessType.name}
      onChange={(e) => {
        const selectedValue = e.target.value;
        const selectedKey = accessTypes.findIndex(
          (nt) => nt.name === selectedValue
        );
        onAccessTypeSelect(accessTypes[selectedKey]);
      }}
      disabled={!editable}
      data-testid="access-type-selector"
    >
      {accessTypes.map((nt, idx) => {
        return (
          <option key={idx + 1} value={nt.name}>
            {nt.name}
          </option>
        );
      })}
    </select>
  );
};

export default AccessTypeSelector;
