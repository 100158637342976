import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useUsers } from "../hooks/use-users";
import MainNav from "../components/main-nav/main-nav";
import { User } from "../models/user"; // Import User class from "../models/user" instead of "../models/user" directly
import styles from "./register.module.css";

const Register = () => {
  let navigate = useNavigate();

  const { createUser } = useUsers({});

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      email: "",
    },
  });

  const username = watch("username");
  const password = watch("password");
  const email = watch("email");

  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const handleRegister = (e) => {
    setMessage("");
    setLoading(true);
    setSuccessful(false);

    const user = new User({ username, email, password });

    createUser.mutateAsync(user).then(
      (response) => {
        setMessage(response.message);
        setSuccessful(true);
        navigate("/login");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setSuccessful(false);
      }
    );

    setLoading(false);
  };

  return (
    <div data-testid="register-page">
      <MainNav />
      <main className={styles.register}>
        <article>
          <div className={styles.registerForm}>
            <hgroup>
              <h3>Sign up</h3>
              <h4>Register for an account</h4>
            </hgroup>
            {!successful && (
              <form
                data-testid="register-form"
                onSubmit={handleSubmit((data) => {
                  handleRegister();
                })}
              >
                <div>
                  <input
                    {...register("username", {
                      required: "Username is required",
                    })}
                    className={errors?.username?.message ? "error" : "no-error"}
                    type="text"
                    autoCapitalize="off"
                    autoComplete="off"
                    id="username"
                    name="username"
                    value={username}
                    placeholder="Username"
                    aria-label="Username"
                    data-testid="username-input"
                  />
                  <p className="error">{errors.username?.message}</p>
                </div>

                <div>
                  <input
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[+\w0-9._-]+@[\w0-9._-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address",
                      },
                    })}
                    className={errors?.email?.message ? "error" : "no-error"}
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    aria-label="Email"
                  />
                  <p className="error">{errors.email?.message}</p>
                </div>

                <div>
                  <input
                    {...register("password", {
                      required: "Password is required",
                      minLength: { value: 4, message: "Min length is 4" },
                    })}
                    className={errors?.password?.message ? "error" : "no-error"}
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    placeholder="Password"
                    aria-label="Password"
                    data-testid="password-input"
                  />
                  <p className="error">{errors.password?.message}</p>
                </div>

                <input
                  type="submit"
                  value="Sign up"
                  aria-busy={loading}
                  data-testid="submit-button"
                />

                <div>
                  <Link to="/login">Login instead</Link>
                </div>
              </form>
            )}
          </div>
        </article>

        {!successful && <p className="error">{message}</p>}
      </main>
    </div>
  );
};

export default Register;
