import { useState, useEffect } from "react";
import Select from "react-select";

const customStyles = {
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "var(--pico-primary)"
      : "var(--pico-secondary-background)",
    color: "white",
    "&:hover": {
      ...base,
      backgroundColor: "var(--pico-secondary-hover-background)",
      color: "white",
    },
  }),

  control: (base) => ({
    ...base,
    backgroundColor: "var(--pico-muted-color)",
    border: "none",
    boxShadow: "none",
    width: "10rem",
  }),

  singleValue: (base) => ({
    ...base,
    color: "var(--pico-primary-inverse)",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "var(--pico-secondary-inverse)",
    "&:hover": {
      color: "var(--pico-secondary-hover)",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
};

const ThemeColorSelector = () => {
  const [themeColor, setThemeColor] = useState(
    localStorage.getItem("themeColor") || "blue"
  );

  const colorOptions = [
    { value: "violet", label: "Violet" },
    { value: "pink", label: "Pink" },
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
    { value: "orange", label: "Orange" },
    { value: "red", label: "Red" },
    { value: "indigo", label: "Indigo" },
    { value: "purple", label: "Purple" },
    { value: "azure", label: "Azure" },
    { value: "cyan", label: "Cyan" },
    { value: "jade", label: "Jade" },
  ];

  const getColorOption = (color) => {
    const colorOption = colorOptions.find((option) => option.value === color);
    return colorOption;
  };

  useEffect(() => {
    const localThemeColor = localStorage.getItem("themeColor");
    if (localThemeColor) {
      setThemeColor(localThemeColor);
    } else {
      localStorage.setItem("themeColor", themeColor);
    }

    updateThemeColor(themeColor);
  }, [themeColor]);

  const updateThemeColor = (themeColor) => {
    const links = Array.from(
      document.querySelectorAll('link[rel="stylesheet"]')
    );
    const link = links.find((l) =>
      l.href.includes("https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.")
    );

    if (link) {
      link.href = `https://cdn.jsdelivr.net/npm/@picocss/pico@2/css/pico.${themeColor}.min.css`;
      localStorage.setItem("themeColor", themeColor);
    }
  };

  const onThemeColorChange = (option) => {
    updateThemeColor(option.value);
  };

  return (
    <div>
      <Select
        placeholder="Theme Color"
        defaultValue={getColorOption(themeColor)}
        onChange={onThemeColorChange}
        options={colorOptions}
        styles={customStyles}
      />
    </div>
  );
};

export default ThemeColorSelector;
