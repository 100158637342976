import { useState } from "react";
import styles from "./message.module.scss";

const Message = ({ msg }) => {
  const [message, setMessage] = useState(msg);

  return (
    <div>
      <label
        className={`${styles.message} ${
          msg.type === "question" ? styles.question : styles.answer
        }`}
      >
        {message.text}
      </label>
    </div>
  );
};

export default Message;
