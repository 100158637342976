import { useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import Actions from "../../../multi-action/actions";

const TryMultiAction = () => {
  const [showConfetti, setShowConfetti] = useState(false);

  const actions = [
    {
      title: "Step 1",
      action: () => {
        console.log("Step 1");
        return new Promise((resolve) => {
          const timer = setTimeout(() => {
            console.log("Step 1 complete");
          }, 5000);
          resolve(timer);
        });
      },
    },
    {
      title: "Step 2",
      action: () => {
        console.log("Step 2");
        return new Promise((resolve) => {
          const timer = setTimeout(() => {
            console.log("Step 2 complete");
          }, 50);
          resolve(timer);
        });
      },
    },
    {
      title: "Step 3",
      action: () => {
        console.log("Step 3");
        return new Promise((resolve) => {
          const timer = setTimeout(() => {
            console.log("Step 3 complete");
          }, 8000);
          resolve(timer);
        });
      },
    },
  ];

  return (
    <div className="try-things">
      {showConfetti && <ConfettiExplosion duration={4000} />}
      <Actions
        className="multi-action"
        actions={actions}
        complete={() => {
          setShowConfetti(true);
        }}
      />
    </div>
  );
};

export default TryMultiAction;
