import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useUserActions } from "../services/api/user-service";
import MainNav from "../components/main-nav/main-nav";
import styles from "./reset-password.module.css";

const ResetPassword = () => {
  let navigate = useNavigate();
  let { code, email } = useParams();

  const userActions = useUserActions();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  register("passwordConfirm", {
    validate: (value) =>
      value === getValues("password") || "The passwords do not match",
  });

  const password = watch("password");
  const passwordConfirm = watch("passwordConfirm");

  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const handleResetPassword = (e) => {
    setMessage("");
    setLoading(true);
    setSuccessful(false);

    userActions.resetPassword(password, code).then(
      (response) => {
        setMessage(response.message);
        setSuccessful(true);
        navigate("/login");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setSuccessful(false);
      }
    );

    setLoading(false);
  };

  return (
    <div data-testid="reset-password-page">
      <MainNav />
      <main className={styles.resetPassword}>
        <article>
          <div className={styles.resetPasswordForm}>
            <hgroup>
              <h3>Choose a New Password</h3>
              <h4>{email}</h4>
            </hgroup>
            {!successful && (
              <form
                onSubmit={handleSubmit((data) => {
                  handleResetPassword();
                })}
                data-testid="reset-password-form"
              >
                <div>
                  <input
                    {...register("password", {
                      required: "Password is required",
                      minLength: { value: 4, message: "Min length is 4" },
                    })}
                    className={errors?.password?.message ? "error" : "no-error"}
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    placeholder="New password"
                    aria-label="New password"
                    data-testid="password"
                  />
                  <p className="error">{errors.password?.message}</p>
                </div>

                <div>
                  <input
                    {...register("passwordConfirm", {
                      required: "Password is required",
                      minLength: { value: 4, message: "Min length is 4" },
                    })}
                    className={
                      errors?.passwordConfirm?.message ? "error" : "no-error"
                    }
                    type="password"
                    id="passwordConfirm"
                    name="passwordConfirm"
                    value={passwordConfirm}
                    placeholder="Confirm new password"
                    aria-label="Confirm new password"
                    data-testid="password-confirm"
                  />
                  <p className="error">{errors.passwordConfirm?.message}</p>
                </div>

                <input
                  type="submit"
                  value="Change Password"
                  aria-busy={loading}
                  data-testid="submit"
                />
              </form>
            )}
          </div>
        </article>

        {!successful && <p className="error">{message}</p>}
      </main>
    </div>
  );
};

export default ResetPassword;
