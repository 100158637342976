import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { useUserActions } from "../../services/api/user-service";
import EventBus from "../../common/event-bus";
import { ReactComponent as LogoSvg } from "../../assets/svg/yogi.svg";
import ThemeToggler from "../theme-toggler/theme-toggler";
import ThemeColorSelector from "../theme-color-selector/theme-color-selector";
import AccountMenu from "../account-menu/account-menu";
import MenuItem from "../account-menu/menu-item";
import { isOnboardingAtom } from "../../services/state/state";
import { useUsers } from "../../hooks/use-users";
import styles from "./main-nav.module.scss";

const MainNav = () => {
  const loggedInUser = useUserActions().getLoggedInUser();
  let userId = null;
  if (loggedInUser) {
    userId = loggedInUser.userId;
  }

  const userActions = useUserActions();

  const isOnboarding = useRecoilValue(isOnboardingAtom);

  const { isUserLoading, userError, user } = useUsers({
    userId,
  });

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = () => {
    userActions.logout();
  };

  return (
    <nav className={styles.mainNavbar}>
      <ul>
        <li>
          <Link to={"/"}>
            <LogoSvg className={styles.logo} />
          </Link>
        </li>
        <li>
          <div className={styles.title}>Yogiwise (beta)</div>
          <div className={styles.subtitle}>Manage better.</div>
        </li>
      </ul>

      <ul>
        <li>
          <ThemeColorSelector />
        </li>
        <li>
          <ThemeToggler />
        </li>
        {user && (
          <li>
            <AccountMenu userName={user.username}>
              {!isOnboarding && (
                <MenuItem>
                  <Link to="/home/profile">Profile</Link>
                </MenuItem>
              )}
              <MenuItem>
                <a href="/login" onClick={logOut}>
                  Log out
                </a>
              </MenuItem>
            </AccountMenu>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default MainNav;
