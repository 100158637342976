import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { isOnboardingAtom } from "../../services/state/state";
import OnboardingStep1 from "./onboarding-step-1";
import OnboardingStep2 from "./onboarding-step-2";
import OnboardingStep3 from "./onboarding-step-3";
import styles from "./onboarding.module.scss";

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const setIsOnboarding = useSetRecoilState(isOnboardingAtom);

  useEffect(() => {
    setIsOnboarding(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const goNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const steps = [
    <OnboardingStep1 handleNext={goNextStep} />,
    <OnboardingStep2 handlePrevious={goPreviousStep} handleNext={goNextStep} />,
    <OnboardingStep3 handlePrevious={goPreviousStep} />,
  ];

  return (
    <div data-testid="onboarding">
      <h2>
        Step {currentStep + 1} of {steps.length}
      </h2>
      <div className={styles.onboardingStep}>{steps[currentStep]}</div>
    </div>
  );
};

export default Onboarding;
