import React, { useState } from "react";
import { MdClear } from "react-icons/md";
import styles from "./search.module.scss";

const Search = ({ placeholder, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  const handleClear = () => {
    setSearchTerm("");
    onSearch("");
  };

  return (
    <div className={styles.searchBar}>
      <input
        className={styles.input}
        type="search"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleSearch}
        data-testid="search-input"
      />
      {searchTerm && (
        <MdClear
          onClick={handleClear}
          className={styles.clearSearch}
          size="24"
          data-testid="clear-button"
          aria-label="Clear search term"
        />
      )}
    </div>
  );
};

export default Search;
