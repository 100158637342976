import { Link } from "react-router-dom";
import { useTeams } from "../../hooks/use-teams";

const Teams = () => {
  const { isTeamsLoading, teamsError, teams, deleteTeam } = useTeams({});

  const handleDelete = (teamId) => {
    deleteTeam.mutate(teamId);
  };

  const renderTable = () => {
    return teams.map((team) => {
      return (
        <tr key={team.id}>
          <td>{team.name}</td>
          <td>
            <Link to={`${team.id}/edit`} data-testid={`edit-link-${team.id}`}>
              Edit
            </Link>
            <Link
              onClick={() => handleDelete(team.id)}
              data-testid={`delete-link-${team.id}`}
            >
              Delete
            </Link>
          </td>
        </tr>
      );
    });
  };

  if (isTeamsLoading) {
    return <article aria-busy="true"></article>;
  }

  if (teamsError) {
    return <article>{teamsError.message}</article>;
  }

  return (
    <div data-testid="teams">
      <article>
        <header>Manage Teams</header>
        <table role="grid" id="teams">
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </table>
        <footer>
          <Link to="create">Add New (+)</Link>
        </footer>
      </article>
    </div>
  );
};
export default Teams;
