import { API_URL } from "../../constants";
import axiosInstance from "./api";
export { useLoggingActions };

const BATCH_SIZE = 10;
const LOCAL_STORAGE_KEY = "logBatch";

const getStoredLogs = () => {
  const storedLogs = localStorage.getItem(LOCAL_STORAGE_KEY);
  return storedLogs ? JSON.parse(storedLogs) : [];
};

const saveLogsToStorage = (logs) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(logs));
};

const clearStoredLogs = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

function useLoggingActions() {
  const sendLogsToBackend = async (logs) => {
    try {
      await axiosInstance.post(
        `${API_URL}/logs`,
        {
          logs: logs,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return true;
    } catch (error) {
      console.error("Error sending logs to backend:", error);
    }
  };

  const logToClient = async (message) => {
    const logBatch = getStoredLogs();
    logBatch.push({
      ts_origin: new Date().toISOString(),
      source: "web-client",
      message: message,
    });
    saveLogsToStorage(logBatch);

    if (logBatch.length >= BATCH_SIZE) {
      console.log("Sending logs to backend");
      const success = await sendLogsToBackend(logBatch.slice());
      if (success) {
        clearStoredLogs();
      }
    }
  };

  return { logToClient };
}
