import { useState } from "react";
import { GuruEditor } from "../editor/guru-editor";
import { Note } from "../../models/note";
import { useUserActions } from "../../services/api/user-service";
import styles from "./agenda-item-detail.module.scss";

const AgendaItemDetail = ({ note, onNoteUpdate, onModalClose }) => {
  const [noteTitle, setNoteTitle] = useState(note.title);
  const [noteText, setNoteText] = useState(note.text);
  const [noteRichText, setNoteRichText] = useState(note.richText);

  const userActions = useUserActions();
  const currentUserCanEditNote = note.isEditableBy(
    userActions.getLoggedInUser().userId
  );

  const onSubmit = (e) => {
    e.preventDefault();
    onNoteUpdate(
      new Note({
        ...note,
        title: noteTitle,
        text: noteText,
        richText: noteRichText,
      })
    );
    onModalClose(false);
  };

  return (
    <div className={styles.modal} data-testid="agenda-item-detail">
      <dialog open>
        <article>
          <header>
            <button
              aria-label="Close"
              rel="prev"
              onClick={(event) => {
                event.stopPropagation();
                onModalClose(false);
              }}
              data-testid="close-button"
            />
            {currentUserCanEditNote && (
              <p>
                <strong>Edit Agenda Item</strong>
              </p>
            )}
          </header>
          <form onSubmit={onSubmit} data-testid="agenda-item-detail-form">
            <label>Title</label>
            <input
              type="text"
              defaultValue={note.title}
              readOnly={!currentUserCanEditNote}
              onChange={(e) => {
                setNoteTitle(e.target.value);
              }}
              data-testid="note-title"
            />
            <div className={styles.myEditor}>
              <GuruEditor
                defaultValue={note.richText || ""}
                onTextChanged={(text, richText) => {
                  setNoteText(text);
                  setNoteRichText(richText);
                }}
                readOnly={!currentUserCanEditNote}
                data-testid="note-editor"
              />
            </div>
            <input
              id="editor-save-button"
              type="submit"
              disabled={!currentUserCanEditNote}
              value="Save"
              data-testid="submit-button"
            />
          </form>
        </article>
      </dialog>
    </div>
  );
};

export default AgendaItemDetail;
