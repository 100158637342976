import { useEffect, useRef } from "react";
import Message from "./message";
import styles from "./messages.module.scss";

const Messages = ({ messages }) => {
  const endOfList = useRef(null);

  useEffect(() => {
    endOfList.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className={styles.messages}>
      <ul data-testid="messages-container">
        {messages.map((message, index) => (
          <li key={message.id} data-testid={`message-${message.id}`}>
            <Message msg={message} />
          </li>
        ))}
      </ul>
      <div ref={endOfList} />
    </div>
  );
};

export default Messages;
