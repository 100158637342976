export class Note {
  constructor({
    id,
    ownerId,
    title,
    text,
    richText,
    noteTypes,
    isAgendaItem,
    isArchived,
    accessType,
    personId,
    createdAt,
    modifiedAt,
  }) {
    this.id = id;
    this.ownerId = ownerId;
    this.title = title;
    this.text = text;
    this.richText = richText;
    this.noteTypes = noteTypes;
    this.isAgendaItem = isAgendaItem;
    this.isArchived = isArchived;
    this.accessType = accessType;
    this.personId = personId;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
  }

  isOwnedBy(userId) {
    return this.ownerId === userId;
  }

  isEditableBy(userId) {
    return (
      this.isOwnedBy(userId) || this.accessType.value === "shared-can-edit"
    );
  }
}
