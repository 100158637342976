export class User {
  constructor({
    id,
    username,
    email,
    password,
    firstName,
    lastName,
    phone,
    dateOfBirth,
    gender,
    avatar,
    createdAt,
    modifiedAt,
  }) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.password = password;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.avatar = avatar;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
  }
}
