import React from "react";
import { useNavigate } from "react-router-dom";
import "./hello.scss";

const Hello = () => {
  let navigate = useNavigate();
  return (
    <div className="hello">
      <h1>Hello</h1>
    </div>
  );
};

export default Hello;
