import React, { useState, useEffect, useRef } from "react";
import { set, useForm } from "react-hook-form";
import { useUserActions } from "../../services/api/user-service";
import NoteTypeSelector from "./note-type-selector";
import AccessTypeSelector from "../access-type-selector/access-type-selector";
import { GuruEditor } from "../editor/guru-editor";
import styles from "./note-detail.module.scss";

const NoteDetail = React.forwardRef(
  ({ note, noteTypeOptions, noteAccessTypes, onSave, onDelete }, ref) => {
    // For the editor, we need to keep track of the text and rich text separately
    // because GuruEditor uses the Lexical editor internally which cannot be used
    // as a controlled component. If we update noteToEdit directly every time the
    // text changes, the editor will lose focus. So we keep the text and rich text
    // in separate state variables and only update noteToEdit when we need to save.
    const [noteToEdit, setNoteToEdit] = useState(note);
    const [noteText, setNoteText] = useState(note.text);
    const [noteRichText, setNoteRichText] = useState(note.richText);
    const [isDirty, setIsDirty] = useState(false);

    const submitButton = useRef(null);

    const SAVE_INTERVAL_IN_MILLIS = 10000;

    const userActions = useUserActions();

    const currentUserOwnsNote = note.isOwnedBy(
      userActions.getLoggedInUser().userId
    );
    const currentUserCanEditNote = note.isEditableBy(
      userActions.getLoggedInUser().userId
    );

    useEffect(() => {
      setNoteToEdit(note);
    }, [note]);

    useEffect(() => {
      const timer = setInterval(() => {
        if (isDirty) {
          submitButton.current.click();
          setIsDirty(false);
        }
      }, SAVE_INTERVAL_IN_MILLIS);
      return () => {
        clearInterval(timer);
      };
    }, [isDirty]);

    const {
      handleSubmit,
      formState: { errors },
    } = useForm();

    const onSubmit = (e) => {
      onSave({
        id: note.id,
        title: noteToEdit.title,
        text: noteText,
        richText: noteRichText,
        noteTypes: noteToEdit.noteTypes,
        accessType: noteToEdit.accessType,
        isAgendaItem: noteToEdit.isAgendaItem,
        isArchived: noteToEdit.isArchived,
      });
    };

    const deleteNote = (noteId) => {
      console.log(`deleting note with id ${noteId}`);
      onDelete(noteId);
    };

    if (!note) {
      return <div className={styles.noNotesMessage}>No note selected</div>;
    }

    return (
      <form
        className={styles.noteDetail}
        onSubmit={handleSubmit((data) => {
          onSubmit();
        })}
        data-testid="note-detail"
      >
        <ul>
          <li>
            <div className={styles.noteTypes}>
              <NoteTypeSelector
                selectedValue={noteToEdit.noteTypes[0]}
                noteTypes={noteTypeOptions}
                onNoteTypeSelect={(newValue) => {
                  setNoteToEdit({
                    ...noteToEdit,
                    noteTypes: [{ name: newValue }],
                  });
                }}
                editable={currentUserCanEditNote}
              />
            </div>
          </li>
          <div className={styles.accessTypes}>
            <AccessTypeSelector
              accessTypes={noteAccessTypes}
              selectedAccessType={noteToEdit.accessType}
              onAccessTypeSelect={(newAccessType) => {
                setNoteToEdit({
                  ...noteToEdit,
                  accessType: newAccessType,
                });
              }}
              editable={currentUserCanEditNote}
            />
          </div>
          <li className={styles.checkboxContainers}>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={noteToEdit.isAgendaItem}
                onChange={(e) => {
                  setNoteToEdit({
                    ...noteToEdit,
                    isAgendaItem: e.target.checked,
                  });
                }}
                disabled={!currentUserCanEditNote}
              />
              <label>Agenda Item</label>
            </div>
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={noteToEdit.isArchived}
                onChange={(e) => {
                  setNoteToEdit({
                    ...noteToEdit,
                    isArchived: e.target.checked,
                  });
                }}
                disabled={!currentUserCanEditNote}
              />
              <label>Archived</label>
            </div>
          </li>
        </ul>
        <label>
          Title
          <input
            type="text"
            value={noteToEdit.title}
            readOnly={!currentUserCanEditNote}
            onChange={(e) => {
              setIsDirty(isDirty || e.target.value !== noteToEdit.title);
              setNoteToEdit({ ...noteToEdit, title: e.target.value });
            }}
            ref={ref}
            data-testid="note-title"
          />
        </label>
        <div className={styles.myEditor}>
          <GuruEditor
            defaultValue={noteToEdit.richText || ""}
            placeholder="Write a note..."
            onTextChanged={(text, richText) => {
              setIsDirty(
                isDirty || text !== noteText || richText !== noteRichText
              );
              setNoteText(text);
              setNoteRichText(richText);
            }}
            readOnly={!currentUserCanEditNote}
          />
        </div>
        <footer>
          <input
            className={styles.saveButton}
            id="editor-save-button"
            type="submit"
            value="Save"
            disabled={!currentUserCanEditNote}
            ref={submitButton}
            data-testid="submit-button"
          />
          {currentUserOwnsNote && (
            <a
              onClick={() => {
                deleteNote(note.id);
              }}
            >
              Delete
            </a>
          )}
        </footer>
      </form>
    );
  }
);

export default NoteDetail;
