import styles from "./career-dashboard.module.scss";
import { useLoggingActions } from "../../services/api/logging-service";

const CareerDashboard = () => {
  const loggingActions = useLoggingActions();

  loggingActions.logToClient("CareerDashboard mounted");
  return (
    <div data-testid="career-dashboard">
      <h1>Career Dashboard</h1>
    </div>
  );
};

export default CareerDashboard;
