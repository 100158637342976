import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUserActions } from "../services/api/user-service";

const Warden = () => {
  const navigate = useNavigate();
  const userActions = useUserActions();

  useEffect(() => {
    const loggedInUser = userActions.getLoggedInUser();

    if (loggedInUser?.token) {
      // if (user.roles.includes("ROLE_ADMIN")) {
      //   console.log("user role includes admin");
      //   navigate("/admin");
      // } else {
      //   navigate("/home/main");
      // }
      console.log("there is a user - go to home page");
      navigate("/home");
    } else {
      console.log("there is no user - go to login page");
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default Warden;
