import { useState, useEffect } from "react";

const CareerLadder = ({ props }) => {
  const [ladder, setLadder] = useState(new Map());

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,

      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;

        return v.toString(16);
      }
    );
  }

  const keyedValue = (value) => {
    return { key: uuidv4(), value: value };
  };

  const templateLadder = new Map([
    ["levels", [keyedValue("IC1"), keyedValue("IC2"), keyedValue("IC3")]],

    ["areas", [keyedValue("Communication"), keyedValue("Collaboration")]],

    [
      "data",

      [
        [
          keyedValue("IC1 comms 1"),

          keyedValue("IC2 comms 1"),

          keyedValue("IC3 comms 1"),
        ],

        [
          keyedValue("IC1 collab 1"),

          keyedValue("IC2 collab 1"),

          keyedValue("IC3 collab 1"),
        ],
      ],
    ],
  ]);

  const getLadderLevels = (ld) => {
    return ld.get("levels");
  };

  useEffect(() => {
    const json_string = localStorage.getItem("ladder");

    if (json_string == null) {
      setLadder(templateLadder);
    } else {
      const json_parsed = JSON.parse(json_string);

      const map_from_json = new Map([
        [json_parsed[0].key, json_parsed[0].value],

        [json_parsed[1].key, json_parsed[1].value],

        [json_parsed[2].key, json_parsed[2].value],
      ]);

      setLadder(map_from_json);
    }
  }, []);

  const addArea = () => {
    const newLadder = new Map(ladder);

    newLadder.get("areas").push(keyedValue("New Area"));

    newLadder

      .get("data")

      .push([keyedValue(""), keyedValue(""), keyedValue("")]);

    setLadder(newLadder);
  };

  const removeArea = (k) => {
    const newLadder = new Map(ladder);

    const idx = newLadder.get("areas").findIndex((e) => e.key === k.key);

    newLadder.set(
      "areas",

      newLadder.get("areas").filter((e) => e.key !== k.key)
    );

    newLadder.get("data").splice(idx, 1);

    newLadder.set("data", newLadder.get("data"));

    setLadder(newLadder);
  };

  const removeLevel = (level, i) => {
    const newLadder = new Map(ladder);

    newLadder.set(
      "levels",

      newLadder.get("levels").filter((e) => e.key !== level.key)
    );

    newLadder.get("data").forEach((area) => {
      area.splice(i, 1);
    });

    console.log(newLadder);

    setLadder(newLadder);
  };

  const updateLevelTitle = (k, newTitle) => {
    const newLadder = new Map(ladder);

    const idx = newLadder.get("levels").findIndex((e) => e.key === k.key);

    newLadder.get("levels")[idx] = keyedValue(newTitle);

    setLadder(newLadder);
  };

  const updateAreaNameAtIndex = (areaIndex, newArea) => {
    const newLadder = new Map(ladder);

    newLadder.get("areas")[areaIndex] = keyedValue(newArea);

    setLadder(newLadder);
  };

  const updateAreaDescriptionAtIndex = (
    areaIndex,

    description,

    newDescription
  ) => {
    const newLadder = new Map(ladder);

    const levelIndex = newLadder

      .get("data")

      [areaIndex].findIndex((e) => e.key === description.key);

    newLadder.get("data")[areaIndex][levelIndex] = keyedValue(newDescription);

    setLadder(newLadder);
  };

  const save = () => {
    const toObject = (map = new Map()) =>
      Array.from(map.entries(), ([k, v]) =>
        v instanceof Map ? { key: k, value: toObject(v) } : { key: k, value: v }
      );

    const json = JSON.stringify(toObject(ladder));

    localStorage.setItem("ladder", json);
  };

  return (
    <>
      <article>
        <table id="ladder">
          <thead>
            <tr key={uuidv4()}>
              <th key={uuidv4()}></th>

              {ladder.size > 0 &&
                getLadderLevels(ladder).map((k) => {
                  return (
                    <th key={k.key}>
                      <input
                        type="text"
                        defaultValue={k.value}
                        onBlur={(e) => updateLevelTitle(k, e.target.value)}
                      ></input>
                    </th>
                  );
                })}
            </tr>
          </thead>

          <tbody>
            {ladder.size > 0 &&
              ladder.get("areas").map((area, areaIndex) => {
                return (
                  <tr key={uuidv4()}>
                    <td key={area.key}>
                      <input
                        id={area.key}
                        type="text"
                        defaultValue={area.value}
                        placeholder="area"
                        onBlurCapture={(e) =>
                          updateAreaNameAtIndex(areaIndex, e.target.value)
                        }
                      ></input>
                    </td>

                    {ladder.get("data")[areaIndex].map((description) => {
                      return (
                        <td key={description.key}>
                          <input
                            type="text"
                            defaultValue={description.value}
                            placeholder="write something"
                            onBlur={(e) =>
                              updateAreaDescriptionAtIndex(
                                areaIndex,

                                description,

                                e.target.value
                              )
                            }
                          ></input>
                        </td>
                      );
                    })}

                    <td key={uuidv4()}>
                      <button onClick={() => removeArea(area)}>Remove</button>
                    </td>
                  </tr>
                );
              })}

            <tr key={uuidv4()}>
              <td key={uuidv4()}></td>

              {ladder.size > 0 &&
                getLadderLevels(ladder).map((level, i) => {
                  return (
                    <td key={level.key}>
                      <button onClick={() => removeLevel(level, i)}>
                        Remove
                      </button>
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      </article>

      <button onClick={() => addArea()}>Add (+)</button>

      <button onClick={() => save()}>Save</button>
    </>
  );
};

export default CareerLadder;
