import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useUserActions } from "../../services/api/user-service";
import { GuruEditor } from "../editor/guru-editor";
import { Meeting as MeetingModel } from "../../models/meeting";
import AccessTypeSelector from "../access-type-selector/access-type-selector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./meeting-note.module.scss";

const MeetingNote = ({ meeting, meetingAccessTypes, onSave, onDelete }) => {
  // For the editor, we need to keep track of the text and rich text separately
  // because GuruEditor uses the Lexical editor internally which cannot be used
  // as a controlled component. If we update meetingToEdit directly every time the
  // text changes, the editor will lose focus. So we keep the text and rich text
  // in separate state variables and only update meetingToEdit when we need to save.
  const [meetingToEdit, setMeetingToEdit] = useState(meeting);
  const [meetingText, setMeetingText] = useState(meeting?.text);
  const [meetingRichText, setMeetingRichText] = useState(meeting?.richText);
  const [isDirty, setIsDirty] = useState(false);

  const submitButton = useRef(null);

  const SAVE_INTERVAL_IN_MILLIS = 10000;

  const userActions = useUserActions();

  const currentUserOwnsMeeting = meeting.isOwnedBy(
    userActions.getLoggedInUser().userId
  );
  const currentUserCanEditMeeting = meeting.isEditableBy(
    userActions.getLoggedInUser().userId
  );

  useEffect(() => {
    setMeetingToEdit(meeting);
  }, [meeting]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isDirty) {
        submitButton.current.click();
        setIsDirty(false);
      }
    }, SAVE_INTERVAL_IN_MILLIS);
    return () => {
      clearInterval(timer);
    };
  }, [isDirty]);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const saveMeeting = () => {
    if (!meeting) {
      return;
    }

    const newMeeting = new MeetingModel({
      id: meeting.id,
      ownerId: meeting.ownerId,
      personId: meetingToEdit.personId,
      title: meetingToEdit.title,
      text: meetingText,
      richText: meetingRichText,
      date: meetingToEdit.date,
      accessType: meetingToEdit.accessType,
    });
    onSave(newMeeting);
    setIsDirty(false);
  };

  const deleteMeeting = (meetingId) => {
    console.log(`deleting meeting with id ${meetingId}`);
    onDelete(meetingId);
  };

  if (!meeting) {
    return <div className={styles.noMeetingMessage}>No meeting selected</div>;
  }

  return (
    <form
      className={styles.meetingNote}
      onSubmit={handleSubmit((data) => {
        saveMeeting();
      })}
      data-testid="meeting-note"
    >
      <header>
        <div className={styles.meetingDate}>
          <DatePicker
            className={styles.datePicker}
            id="meeting-date"
            selected={meetingToEdit.date}
            onChange={(date) => {
              setMeetingToEdit({ ...meetingToEdit, date });
              setIsDirty(true);
            }}
            {...(currentUserCanEditMeeting ? {} : { disabled: true })}
          />
        </div>
        <div className={styles.accessTypes}>
          <AccessTypeSelector
            accessTypes={meetingAccessTypes}
            selectedAccessType={meetingToEdit.accessType}
            onAccessTypeSelect={(newAccessType) => {
              setMeetingToEdit({
                ...meetingToEdit,
                accessType: newAccessType,
              });
            }}
            editable={currentUserCanEditMeeting}
          />
        </div>
      </header>
      <div className={styles.myEditor}>
        <GuruEditor
          defaultValue={meeting?.richText || ""}
          onTextChanged={(text, richText) => {
            setIsDirty(
              isDirty || text !== meetingText || richText !== meetingRichText
            );
            setMeetingText(text);
            setMeetingRichText(richText);
          }}
          readOnly={!currentUserCanEditMeeting}
        />
      </div>
      <footer>
        <input
          className={styles.saveButton}
          id="editor-save-button"
          type="submit"
          value="Save"
          disabled={!currentUserCanEditMeeting}
          ref={submitButton}
        />
        {meeting.isOwnedBy(userActions.getLoggedInUser().userId) && (
          <a
            onClick={() => {
              deleteMeeting(meeting.id);
            }}
          >
            Delete
          </a>
        )}
      </footer>
    </form>
  );
};

export default MeetingNote;
