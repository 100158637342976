import { useState } from "react";
import { useRecoilValue } from "recoil";
import { usePersons } from "../../hooks/use-persons";
import { currentPersonIDAtom } from "../../services/state/state";
import { Tabs, TabNav, TabOutlet, TabContent, TabNavItem } from "../tab";
import NotesDashboard from "../notes/notes-dashboard";
import MeetingsDashboard from "../meetings/meetings-dashboard";
import CareerDashboard from "../career/career-dashboard";
import styles from "./dashboard.module.scss";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "tab-notes"
  );
  const currentPersonID = useRecoilValue(currentPersonIDAtom);

  const { isPersonsLoading, personsError, persons } = usePersons({});

  const setActiveTabAndSave = (tabId) => {
    setActiveTab(tabId);
    localStorage.setItem("activeTab", tabId);
  };

  if (isPersonsLoading) {
    return <article aria-busy="true" data-testid="loading"></article>;
  }

  if (currentPersonID === null) {
    console.log("No person selected.  Don't show dashboard.");
    return (
      <article className="centered">
        <h1>No person selected</h1>
        <p>Please select a person to view the dashboard.</p>
      </article>
    );
  }

  if (personsError) {
    return <div>Error: {personsError.message}</div>;
  }

  return persons.length > 0 ? (
    <div className={styles.dashboard} data-testid="dashboard">
      <Tabs id="tabs-dashboard">
        <TabNav activeTab={activeTab} setActiveTab={setActiveTabAndSave}>
          <TabNavItem title="Notes" id="tab-notes" />
          <TabNavItem title="1:1s" id="tab-meetings" />
          <TabNavItem title="Career" id="tab-career" />
        </TabNav>

        <TabOutlet>
          <TabContent id="tab-notes" activeTab={activeTab}>
            <NotesDashboard />
          </TabContent>
          <TabContent id="tab-meetings" activeTab={activeTab}>
            <MeetingsDashboard />
          </TabContent>
          <TabContent id="tab-career" activeTab={activeTab}>
            <CareerDashboard />
          </TabContent>
        </TabOutlet>
      </Tabs>
    </div>
  ) : (
    <div className={styles.dashboard}>
      <article className="centered">No persons found</article>
    </div>
  );
};

export default Dashboard;
