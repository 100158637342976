import { useState } from "react";
import { useUserActions } from "../../services/api/user-service";

const TeamJoin = () => {
  const [inviteCode, setInviteCode] = useState("");

  const userActions = useUserActions();

  const acceptInvite = () => {
    console.log(`accepting invite code: ${inviteCode}`);
    userActions.join(inviteCode);
  };

  return (
    <div data-testid="team-join">
      <input
        type="text"
        value={inviteCode}
        placeholder="invite code"
        onChange={(e) => setInviteCode(e.target.value)}
        data-testid="invite-code-input"
      />
      <button
        onClick={() => {
          acceptInvite();
        }}
        data-testid="join-button"
      >
        Join
      </button>
    </div>
  );
};

export default TeamJoin;
