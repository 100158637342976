import { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  currentPersonIDAtom,
  currentTeamIDAtom,
} from "../../services/state/state";
import { usePersons } from "../../hooks/use-persons";
import styles from "./person-selector.module.scss";

const PersonSelector = () => {
  const currentTeamID = useRecoilValue(currentTeamIDAtom);
  const [currentPersonID, setCurrentPersonID] =
    useRecoilState(currentPersonIDAtom);
  const [teamPersons, setTeamPersons] = useState([]);

  const { isPersonsLoading, personsError, persons } = usePersons({});

  const onPersonSelect = (personId) => {
    console.log(`selected person with id ${personId}`);
    setCurrentPersonID(personId);
    localStorage.setItem("selectedPerson", personId);
  };

  useEffect(() => {
    if (isPersonsLoading) {
      return;
    }

    const teamPersons = persons.filter(
      (person) => person.teamId === currentTeamID
    );

    setTeamPersons(teamPersons);

    if (teamPersons?.length > 0) {
      const personExists = teamPersons.some(
        (person) => person.id === currentPersonID
      );
      if (!personExists) {
        onPersonSelect(teamPersons[0].id);
      } else {
        onPersonSelect(currentPersonID);
      }
    } else {
      onPersonSelect(null);
    }
  }, [currentTeamID, persons]);

  const onChange = ({ target: { value } }) => {
    onPersonSelect(value);
  };

  if (isPersonsLoading) {
    return <article aria-busy="true"></article>;
  }

  if (personsError) {
    return <div>{personsError.message}</div>;
  }

  return (
    <select
      className={styles.selector}
      value={currentPersonID || ""}
      onChange={onChange}
      data-testid="person-selector"
    >
      {teamPersons.map((person) => {
        return (
          <option key={person.id} value={person.id}>
            {`${person.firstName} ${person.lastName}`}
          </option>
        );
      })}
    </select>
  );
};

export default PersonSelector;
