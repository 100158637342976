import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { useTeamActions } from "../services/api/team-service";

export const useTeams = ({ teamId }) => {
  const teamActions = useTeamActions();
  const queryClient = useQueryClient();

  const {
    isLoading: isTeamsLoading,
    error: teamsError,
    data: teams,
  } = useQuery({
    queryKey: ["teams"],
    queryFn: teamActions.retrieveTeams,
    enabled: !teamId,
  });

  const {
    isLoading: isTeamLoading,
    error: teamError,
    data: team,
  } = useQuery({
    queryKey: ["team", teamId],
    queryFn: () => {
      return teamActions.retrieveTeam(teamId);
    },
    enabled: !!teamId,
  });

  const createTeam = useMutation({
    mutationFn: ({ name }) => {
      console.log(`creating team with ${name}`);
      return teamActions.createTeam({ name });
    },
    onSuccess: () => {
      console.log("created team successfully");
      queryClient.invalidateQueries("teams");
    },
  });

  const updateTeam = useMutation({
    mutationFn: ({ id, name }) => {
      console.log(`updating team with ${id}, ${name}`);
      return teamActions.updateTeam({ id, name });
    },
    onSuccess: () => {
      console.log("updated team successfully");
      queryClient.invalidateQueries("teams");
    },
  });

  const deleteTeam = useMutation({
    mutationFn: (id) => teamActions.deleteTeam(id),
    onSuccess: () => {
      console.log("deleted team successfully");
      queryClient.invalidateQueries("teams");
    },
  });

  return {
    isTeamsLoading,
    teamsError,
    teams,
    isTeamLoading,
    teamError,
    team,
    createTeam,
    updateTeam,
    deleteTeam,
  };
};
