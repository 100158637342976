import { API_URL } from "../../constants";
import axiosInstance from "./api";
import { Team } from "../../models/team";

export { useTeamActions };

function useTeamActions() {
  const createTeam = async (team) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/teams`, {
        name: team.name,
      });
      return response.data;
    } catch (error) {
      console.error("Error creating team", error);
      throw error;
    }
  };

  const retrieveTeams = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/teams?other=true`);
      const teams = response.data.map((team) => {
        return new Team({
          id: team.id,
          name: team.name,
          createdDate: team.created_date,
          modifiedDate: team.modified_date,
        });
      });
      return teams;
    } catch (error) {
      console.error("Error retrieving teams", error);
      throw error;
    }
  };

  const retrieveTeam = async (id) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/teams/${id}`);
      console.log("retrieved team:", response.data);
      return new Team(response.data);
    } catch (error) {
      console.error(`Error retrieving team with id ${id}`, error);
      throw error;
    }
  };

  const updateTeam = async ({ id, name }) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/teams/${id}`, {
        name,
      });
      return response.data;
    } catch (error) {
      console.error(`Error updating team with id ${id}`, error);
      throw error;
    }
  };

  const deleteTeam = async (id) => {
    try {
      await axiosInstance.delete(`${API_URL}/teams/${id}`);
    } catch (error) {
      console.error(`Error deleting team with id ${id}`, error);
      throw error;
    }
  };

  return {
    createTeam,
    retrieveTeams,
    retrieveTeam,
    updateTeam,
    deleteTeam,
  };
}
