import { useState, useEffect } from "react";
import Meeting from "./meeting";
import MeetingNote from "./meeting-note";
import { useMeetings } from "../../hooks/use-meetings";
import { useAccessTypes } from "../../hooks/use-access-types";
import { Meeting as MeetingModel } from "../../models/meeting";
import styles from "./meetings.module.scss";
import { ReactComponent as NoMeetingsSvg } from "../../assets/svg/pencil-notepad.svg";

const Meetings = ({ personId }) => {
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  const {
    isMeetingsLoading,
    meetingsError,
    meetings,
    createMeeting,
    updateMeeting,
    deleteMeeting,
  } = useMeetings({ personId, filter: {} });

  const { isAccessTypesLoading, accessTypesError, accessTypes } =
    useAccessTypes();

  useEffect(
    () => {
      if (meetings && meetings.length > 0 && selectedMeeting === null) {
        console.log("setting selected meeting to first meeting");
        setSelectedMeeting(meetings[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [meetings]
  );

  useEffect(() => {
    setSelectedMeeting(null);
  }, [personId]);

  const onMeetingCreate = () => {
    const newMeetingText = "";
    const newMeetingRichText = `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${newMeetingText}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;

    const newMeeting = new MeetingModel({
      title: "new meeting title",
      text: newMeetingText,
      richText: newMeetingRichText,
      personId: personId,
      date: new Date(),
    });

    createMeeting.mutateAsync(newMeeting).then((meeting) => {
      console.log(`created meeting with id ${meeting.id}`);
      setSelectedMeeting(null);
    });
  };

  const onMeetingSelect = (meeting) => {
    console.log(
      `selected meeting with id: ${meeting.id}, text: ${meeting.text}`
    );
    setSelectedMeeting(meeting);
  };

  const onMeetingSave = (meeting) => {
    console.log(
      `saving meeting with id: ${meeting.id}, access type: ${meeting.accessType}`
    );
    updateMeeting.mutate(meeting);
  };

  const onMeetingDelete = (meetingId) => {
    console.log(`deleting meeting with id ${meetingId}`);
    deleteMeeting.mutateAsync(meetingId).then((meeting) => {
      console.log(`deleted meeting with id ${meeting.id}`);
      setSelectedMeeting(null);
    });
  };

  const renderMeetings = (meetings) => {
    if (meetings.length === 0) {
      return (
        <>
          <NoMeetingsSvg className={styles.noContent} />
          <div>No Meetings</div>
        </>
      );
    }
    return meetings.map((meeting) => renderMeeting(meeting));
  };

  const renderMeeting = (meeting) => {
    return (
      <li key={meeting.id}>
        <Meeting
          meeting={meeting}
          selected={selectedMeeting?.id === meeting.id}
          onMeetingSelect={onMeetingSelect}
        />
      </li>
    );
  };

  if (isMeetingsLoading || isAccessTypesLoading) {
    return <div data-testid="loading">Loading...</div>;
  }

  if (meetingsError) {
    return <div data-testid="error">Error: {meetingsError.message}</div>;
  }

  if (accessTypesError) {
    return <div data-testid="error">Error: {accessTypesError.message}</div>;
  }

  return (
    <div className={styles.meetings} data-testid="meetings">
      <div className={styles.meetingList}>
        <header>Meetings</header>
        <ul>{renderMeetings(meetings)}</ul>
        <button onClick={() => onMeetingCreate()} style={{ width: "100%" }}>
          New Meeting
        </button>
      </div>

      <div className={styles.meetingDetail}>
        {selectedMeeting && (
          <MeetingNote
            meeting={selectedMeeting}
            meetingAccessTypes={accessTypes}
            onSave={onMeetingSave}
            onDelete={onMeetingDelete}
          />
        )}
      </div>
    </div>
  );
};

export default Meetings;
