import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { authAtom } from "../services/state/state";

import { useUserActions } from "../services/api/user-service";
import MainNav from "../components/main-nav/main-nav";
import styles from "./login.module.css";

const Login = () => {
  const setAuth = useSetRecoilState(authAtom);
  let navigate = useNavigate();

  const userActions = useUserActions();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const username = watch("username");
  const password = watch("password");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleLogin = (e) => {
    setMessage("");
    setLoading(true);

    userActions.login(username, password).then(
      (response) => {
        if (response.token) {
          setAuth(
            JSON.stringify({ userId: response.id, token: response.token })
          );
        }
        navigate("/home");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );

    setLoading(false);
  };

  return (
    <div data-testid="login-page">
      <MainNav />
      <main className={styles.login}>
        <article>
          <div className={styles.loginForm}>
            <hgroup>
              <h3>Sign in</h3>
              <h4>Sign in to your account</h4>
            </hgroup>
            <form
              onSubmit={handleSubmit((data) => {
                handleLogin(data);
              })}
            >
              <div>
                <input
                  {...register("username", {
                    required: "Username is required",
                  })}
                  className={errors?.username?.message ? "error" : "no-error"}
                  type="text"
                  autoCapitalize="off"
                  autoComplete="off"
                  id="username"
                  name="username"
                  placeholder="Username"
                  aria-label="Username"
                  data-testid="username"
                />
                <p className="error">{errors.username?.message}</p>
              </div>

              <div>
                <input
                  {...register("password", {
                    required: "Password is required",
                    minLength: { value: 4, message: "Min length is 4" },
                  })}
                  className={errors?.password?.message ? "error" : "no-error"}
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  aria-label="Password"
                  data-testid="password"
                />
                <p className="error">{errors.password?.message}</p>
              </div>

              <input
                type="submit"
                value="Login"
                aria-busy={loading}
                data-testid="submit"
              />

              <div className={styles.footer}>
                <Link to="/forgot-password">Forgot password?</Link>
                <Link to="/register">Sign up instead</Link>
              </div>

              {message && <p className="error">{message}</p>}
            </form>
          </div>
        </article>
      </main>
    </div>
  );
};

export default Login;
