import { useState, useEffect } from "react";
import { IoMoon, IoSunnySharp } from "react-icons/io5";
import styles from "./theme-toggler.module.scss";

const ThemeToggler = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const root = document.documentElement;
    const localTheme = localStorage.getItem("theme");
    if (localTheme) {
      root.setAttribute("data-theme", localTheme);
      setTheme(localTheme);
    } else {
      root.setAttribute("data-theme", "dark");
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    }
  }, []);

  const toggleTheme = () => {
    const root = document.documentElement;

    // If the current theme is 'dark', change it to 'light', and vice versa
    if (theme === "dark") {
      root.setAttribute("data-theme", "light");
      setTheme("light");
      localStorage.setItem("theme", "light");
    } else {
      root.setAttribute("data-theme", "dark");
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    }
  };

  return theme === "dark" ? (
    <IoSunnySharp className={styles.themeIconLight} onClick={toggleTheme} />
  ) : (
    <IoMoon className={styles.themeIconDark} onClick={toggleTheme} />
  );
};

export default ThemeToggler;
