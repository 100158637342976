import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { useMeetingActions } from "../services/api/meeting-service";

export const useMeetings = ({ personId, meetingId, filter }) => {
  const meetingActions = useMeetingActions();
  const queryClient = useQueryClient();

  const {
    isLoading: isMeetingsLoading,
    error: meetingsError,
    data: meetings,
  } = useQuery({
    queryKey: ["meetings", personId],
    queryFn: () => meetingActions.retrievePersonMeetings(personId, filter),
    enabled: !meetingId,
  });

  const {
    isLoading: isMeetingLoading,
    error: meetingError,
    data: meeting,
  } = useQuery({
    queryKey: ["meetings", personId, meetingId],
    queryFn: () => meetingActions.retrieveMeeting(meetingId),
    enabled: !!meetingId,
  });

  const createMeeting = useMutation({
    mutationFn: (meeting) => meetingActions.createMeeting(meeting),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["meetings"] });
    },
  });

  const updateMeeting = useMutation({
    mutationFn: (meeting) => meetingActions.updateMeeting(meeting),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["meetings"] });
    },
  });

  const deleteMeeting = useMutation({
    mutationFn: (id) => meetingActions.deleteMeeting(id),
    onSuccess: () => {
      console.log("deleted meeting successfully");
      queryClient.invalidateQueries({ queryKey: ["meetings"] });
    },
  });

  return {
    isMeetingsLoading,
    meetingsError,
    meetings,
    isMeetingLoading,
    meetingError,
    meeting,
    createMeeting,
    updateMeeting,
    deleteMeeting,
  };
};
