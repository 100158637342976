import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentTeamIDAtom } from "../../services/state/state";
import { useTeams } from "../../hooks/use-teams";
import styles from "./team-selector.module.scss";

const TeamSelector = () => {
  const [currentTeamID, setCurrentTeamID] = useRecoilState(currentTeamIDAtom);
  const [selectedTeam, setSelectedTeam] = useState(currentTeamID);
  const navigate = useNavigate();

  const { isTeamsLoading, teamsError, teams } = useTeams({});

  useEffect(() => {
    if (isTeamsLoading) {
      return;
    }

    if (currentTeamID === null && teams?.length > 0) {
      console.log(
        "currentTeamID is null, setting to first team with id: ",
        teams[0].id
      );
      setCurrentTeamID(teams[0].id);
      setSelectedTeam(teams[0].id);
      localStorage.setItem("selectedTeam", teams[0].id);
    }

    // Save the currently selected team to localStorage whenever it changes
    console.log(`saving selected team to localStorage: ${currentTeamID}`);
    localStorage.setItem("selectedTeam", currentTeamID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamID, teams]);

  const onChange = ({ target: { value } }) => {
    if (value === "manage") {
      console.log("manage teams selected");
      setSelectedTeam(currentTeamID);
      navigate("teams");
    } else {
      setCurrentTeamID(value);
      setSelectedTeam(value);
    }
  };

  if (isTeamsLoading) {
    return <article aria-busy="true"></article>;
  }

  if (teamsError) {
    return <div>{teamsError.message}</div>;
  }

  return (
    <select
      className={styles.selector}
      value={selectedTeam ? selectedTeam : ""}
      onChange={onChange}
      data-testid="team-selector"
    >
      {teams.map((team) => {
        return (
          <option key={team.id} value={team.id}>
            {`${team.name}`}
          </option>
        );
      })}
      <optgroup label="-----------------" />
      <option value="manage">Manage teams...</option>
    </select>
  );
};

export default TeamSelector;
