import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useTeams } from "../../hooks/use-teams";

const TeamCreate = () => {
  const navigate = useNavigate();

  const { createTeam } = useTeams({});

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const name = watch("name");

  const onSubmit = () => {
    createTeam.mutate({ name });
    navigate("/home/teams");
  };

  return (
    <div className="container" data-testid="team-create">
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit();
        })}
        data-testid="team-create-form"
      >
        <div>
          <label htmlFor="team-name">
            Name
            <input
              {...register("name", {
                required: "Name is required",
              })}
              className={errors?.name?.message ? "error" : "no-error"}
              id="team-name"
              placeholder="Team Name"
            />
          </label>
          <p className="error">{errors.name?.message}</p>
        </div>

        <input type="submit" value="Save" />
      </form>
      <Link onClick={() => navigate(-1)} data-testid="cancel-team-create">
        Cancel
      </Link>
    </div>
  );
};

export default TeamCreate;
