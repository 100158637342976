import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaPeopleArrows,
  FaPerson,
  FaArrowUpRightDots,
  FaBookOpenReader,
} from "react-icons/fa6";
import { ImMagicWand } from "react-icons/im";
import TeamSelector from "../teams/team-selector";
import PersonSelector from "../persons/person-selector";
import styles from "./side-nav.module.scss";

const SideNav = ({ onToggleAssistant }) => {
  const [isAssistantOpen, setIsAssistantOpen] = useState(false);
  const location = useLocation();

  const getLinkClass = (path) => {
    return `${styles.navLink}${
      location.pathname === path ? ` ${styles.active}` : ""
    }`;
  };

  const toggleAssistant = () => {
    setIsAssistantOpen(!isAssistantOpen);
    onToggleAssistant();
  };

  return (
    <aside data-testid="side-nav">
      <nav className={styles.navbar}>
        <ul className={styles.navbarNav}>
          <li className={styles.navItem} data-testid="team-selector">
            <TeamSelector />
          </li>
          <li className={styles.navItem}>
            <PersonSelector />
          </li>
          <li className={styles.navItem}>
            <Link className={getLinkClass("/home/teams")} id="teams" to="teams">
              <FaPeopleArrows className={styles.icon} />
              <span className={styles.linkText}>Teams</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              className={getLinkClass("/home/persons")}
              id="persons"
              to="persons"
            >
              <FaPerson className={styles.icon} />
              <span className={styles.linkText}>Persons</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              className={getLinkClass("/home/dashboard")}
              id="dashboard"
              to="dashboard"
            >
              <FaBookOpenReader className={styles.icon} />
              <span className={styles.linkText}>Dashboard</span>
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              className={getLinkClass("/home/ladders")}
              id="ladders"
              to="ladders"
            >
              <FaArrowUpRightDots className={styles.icon} />
              <span className={styles.linkText}>Ladders</span>
            </Link>
          </li>
        </ul>

        <ul className={styles.navbarNav}>
          <li>
            <button onClick={toggleAssistant} data-testid="assistant-button">
              <ImMagicWand size="16" className={styles.assistantIcon} />
              {isAssistantOpen ? "Hide Assistant" : "Show Assistant"}
            </button>
          </li>
        </ul>

        <ul className={styles.navbarNav}>
          <li className={styles.navItem}>
            <div className={styles.navSeparator}></div>
          </li>
          <li>
            <Link to="teams/join">
              <span className={styles.linkText}>
                Do you have an invite code?
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default SideNav;
