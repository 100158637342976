import TryMultiAction from "./components/multi-action/try-multi-action";
import TryDatePicker from "./components/date-picker/try-date-picker";
import Hello from "./components/hello/hello";
import Progress from "./components/progress/progress";
import styles from "./try-things.module.scss";

const TryThings = () => {
  return (
    <div className={styles.TryThings}>
      <TryDatePicker />
      <TryMultiAction />
      <Hello />
      <Progress />
    </div>
  );
};

export default TryThings;
