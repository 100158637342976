import { useState } from "react";
import styles from "./message-create.module.scss";

const MessageCreate = ({ loading, onCreate }) => {
  const [message, setMessage] = useState("");

  const newMessage = () => {
    console.log(`Creating new assistant message: ${message}`);
    onCreate(message);
    setMessage("");
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // prevent form submission
      newMessage();
    }
  };
  return (
    <div className={styles.messageCreate} data-testid="message-create">
      <input
        placeholder="How can I help you?"
        value={message}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className={styles.messageInput}
        data-testid="message-input"
      />
      <button
        onClick={newMessage}
        className={styles.messageCreateButton}
        aria-busy={loading}
        disabled={loading}
        data-testid="submit-button"
      >
        Ask
      </button>
    </div>
  );
};

export default MessageCreate;
