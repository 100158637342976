import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useAccessTypeActions } from "../services/api/access-types-service";

export const useAccessTypes = () => {
  const accessTypeActions = useAccessTypeActions();
  const queryClient = useQueryClient();

  const {
    isLoading: isAccessTypesLoading,
    error: accessTypesError,
    data: accessTypes,
  } = useQuery({
    queryKey: ["accessTypes"],
    queryFn: accessTypeActions.retrieveAccessTypes,
  });

  return {
    isAccessTypesLoading,
    accessTypesError,
    accessTypes,
  };
};
