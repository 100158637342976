import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { useUserActions } from "../services/api/user-service";

export const useUsers = ({ userId }) => {
  const userActions = useUserActions();
  const queryClient = useQueryClient();

  const {
    isLoading: isUserLoading,
    error: userError,
    data: user,
  } = useQuery({
    queryKey: ["users", userId],
    queryFn: () => {
      if (!userId) {
        return Promise.resolve(null);
      }
      return userActions.retrieveUser(userId);
    },
    enabled: true,
  });

  const createUser = useMutation({
    mutationFn: (user) => {
      console.log(
        `creating user with ${user.username}, ${user.email}, ${user.password}`
      );
      return userActions.createUser(user);
    },
    onSuccess: () => {
      console.log("created user successfully");
      queryClient.refetchQueries({ queryKey: ["users"] });
    },
  });

  const updateUser = useMutation({
    mutationFn: (user) => {
      return userActions.updateUser(user);
    },
    onSuccess: () => {
      console.log("updated user successfully");
      queryClient.invalidateQueries(["users", userId]);
    },
  });

  const deleteUser = useMutation({
    mutationFn: (id) => userActions.deleteUser(id),
    onSuccess: () => {
      console.log("deleted user successfully");
      queryClient.invalidateQueries("users");
    },
  });

  return {
    isUserLoading,
    userError,
    user,
    createUser,
    updateUser,
    deleteUser,
  };
};
