import { formatDate } from "../../common/date-utils";
import { useUserActions } from "../../services/api/user-service";
import styles from "./note.module.scss";

const Note = ({ note, onClick }) => {
  const userActions = useUserActions();

  const onNoteClick = () => {
    onClick(note);
  };

  return (
    <div
      data-testid="note"
      className={styles.noteContent}
      onClick={() => onNoteClick()}
    >
      <div className={styles.noteHeader}>
        <div className={styles.title}>
          {note.title ? note.title : "Untitled Note"}
        </div>
        {!note.isOwnedBy(userActions.getLoggedInUser().userId) && (
          <div className={styles.shared}>Shared</div>
        )}
      </div>
      <div className={styles.text}>{note.text ? note.text : "No content"}</div>
      <footer>
        Created: {formatDate(note.createdAt)}, Last Updated:{" "}
        {formatDate(note.modifiedAt)}
      </footer>
    </div>
  );
};

export default Note;
