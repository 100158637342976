import { useState, useContext } from "react";
import AgendaContext from "../../context/agenda-context";
import AgendaItemDetail from "./agenda-item-detail";
import { Note } from "../../models/note";
import { useUserActions } from "../../services/api/user-service";
import { FaEllipsisH } from "react-icons/fa";
import styles from "./agenda-item.module.scss";

const AgendaItem = ({ note }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const updateNote = useContext(AgendaContext);

  const closeModal = () => setIsModalOpen(false);

  const userActions = useUserActions();
  const currentUserOwnsNote = note.isOwnedBy(
    userActions.getLoggedInUser().userId
  );
  const currentUserCanEditNote = note.isEditableBy(
    userActions.getLoggedInUser().userId
  );

  return (
    <div className={styles.agendaItem} data-testid="agenda-item">
      <input
        type="checkbox"
        onChange={() => {
          updateNote(
            new Note({
              ...note,
              isArchived: !note.isArchived,
            })
          );
        }}
        defaultChecked={note.isArchived}
        disabled={!currentUserCanEditNote}
        data-testid="agenda-item-checkbox"
      />
      <input
        type="text"
        defaultValue={note.title}
        className={`${styles.agendaItemContent} ${
          note.isArchived ? styles.agendaItemArchived : ""
        }`}
        onBlur={(event) =>
          updateNote(
            new Note({
              ...note,
              title: event.target.value,
            })
          )
        }
        disabled={!currentUserCanEditNote}
        data-testid="agenda-item-title"
      />
      <FaEllipsisH
        onClick={() => setIsModalOpen(true)}
        className={styles.agendaItemDetail}
      />
      {isModalOpen && (
        <>
          <div className={styles.backdrop} onClick={closeModal} />
          <AgendaItemDetail
            note={note}
            onNoteUpdate={updateNote}
            onModalClose={closeModal}
          />
        </>
      )}
    </div>
  );
};

export default AgendaItem;
