import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useUserActions } from "../services/api/user-service";
import MainNav from "../components/main-nav/main-nav";
import styles from "./forgot-password.module.css";

const ForgotPassword = (data) => {
  const navigate = useNavigate();

  const userActions = useUserActions();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const email = watch("email");

  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const handleForgotPassword = (e) => {
    setMessage("");
    setLoading(true);
    setSuccessful(false);

    userActions.requestPasswordReset(email).then(
      (response) => {
        setMessage(response.message);
        setSuccessful(true);
        navigate("/login");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setSuccessful(false);
      }
    );

    setLoading(false);
  };

  return (
    <div data-testid="forgot-password-page">
      <MainNav />
      <main className={styles.forgotPassword}>
        <article>
          <div className={styles.forgotPasswordForm}>
            <hgroup>
              <h3>Forgot Password?</h3>
              <h4>Enter your email address to reset</h4>
            </hgroup>
            {!successful && (
              <form
                onSubmit={handleSubmit((data) => {
                  handleForgotPassword(data);
                })}
                data-testid="forgot-password-form"
              >
                <div>
                  <input
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[+\w0-9._-]+@[\w0-9._-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address",
                      },
                    })}
                    className={errors?.email?.message ? "error" : "no-error"}
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    aria-label="Email"
                    data-testid="email-input"
                  />
                  <p className="error">{errors.email?.message}</p>
                </div>

                <input
                  type="submit"
                  value="Reset"
                  aria-busy={loading}
                  data-testid="submit-button"
                />
              </form>
            )}
          </div>
        </article>

        {!successful && <p className="error">{message}</p>}
      </main>
    </div>
  );
};

export default ForgotPassword;
