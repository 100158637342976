import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { onboardingAtom } from "../../services/state/state";
import styles from "./onboarding-step.module.scss";

const OnboardingStep1 = ({ handleNext }) => {
  const [onboarding, setOnboarding] = useRecoilState(onboardingAtom);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: onboarding.teamName || "",
    },
  });

  const name = watch("name");

  const onSubmit = () => {
    setOnboarding({ ...onboarding, teamName: name });
    handleNext();
  };

  return (
    <div data-testid="onboarding-step1">
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit();
        })}
      >
        <div>
          <label htmlFor="team-name">
            Start by creating a team
            <input
              {...register("name", {
                required: "Name is required",
              })}
              className={errors?.name?.message ? "error" : "no-error"}
              id="team-name"
              placeholder="Team Name"
              data-testid="team-name-input"
            />
          </label>
          <p className="error">{errors.name?.message}</p>
        </div>

        <input type="submit" value="Next" />
      </form>
      <div className={styles.onboardingFooter}>
        <Link onClick={handleNext}>Skip for now</Link>
      </div>
    </div>
  );
};

export default OnboardingStep1;
