import { useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useEffect } from "react";
import { useTeams } from "../../hooks/use-teams";

const TeamEdit = () => {
  const navigate = useNavigate();

  let { teamId } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const name = watch("name");

  const { isTeamLoading, teamError, team, updateTeam } = useTeams({ teamId });

  useEffect(() => {
    if (team) {
      setValue("name", team.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    updateTeam.mutate({ id: team.id, name });
    navigate("/home/teams");
  };

  if (isTeamLoading) {
    return <article aria-busy="true"></article>;
  }

  if (teamError) {
    return <div>Error: {teamError.message}</div>;
  }

  return (
    <div className="container" data-testid="team-edit">
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit();
        })}
        data-testid="team-edit-form"
      >
        <div>
          <label htmlFor="team-name">
            Name
            <input
              {...register("name", {
                required: "Name is required",
              })}
              className={errors?.name?.message ? "error" : "no-error"}
              defaultValue={team.name}
              id="team-name"
              placeholder="Team Name"
              data-testid="team-edit-name-input"
            />
          </label>
          <p className="error">{errors.name?.message}</p>
        </div>

        <input type="submit" value="Save" data-testid="team-edit-save-button" />
      </form>
      <Link onClick={() => navigate(-1)}>Cancel</Link>
    </div>
  );
};

export default TeamEdit;
