import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUsers } from "../../hooks/use-users";
import { useUserActions } from "../../services/api/user-service";
import { User } from "../../models/user";
import styles from "./profile.module.css";

const Profile = () => {
  const userActions = useUserActions();

  const userId = userActions.getLoggedInUser().userId;
  const { isUserLoading, userError, user, updateUser } = useUsers({ userId });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      email: "",
      firstName: "",
      lastName: "",
    },
  });

  useEffect(() => {
    if (user) {
      setValue("username", user.username);
      setValue("email", user.email);
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
    }
  }, [user, setValue]);

  const username = watch("username");
  const email = watch("email");
  const firstName = watch("firstName");
  const lastName = watch("lastName");

  const onSubmit = (e) => {
    // update user
    updateUser.mutate(
      new User({
        id: userId,
        username,
        email,
        firstName: firstName,
        lastName: lastName,
      })
    );
  };

  if (isUserLoading) {
    return <div>Loading...</div>;
  }

  if (userError) {
    return <div>Error: {userError.message}</div>;
  }

  return (
    <div className={styles.container}>
      <form className={styles.profileForm} onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="first-name">First Name</label>
        <input
          type="text"
          defaultValue={user.firstName}
          id="first-name"
          placeholder="First Name"
          {...register("firstName", { required: false })}
        />

        <label htmlFor="last-name">Last Name</label>
        <input
          type="text"
          defaultValue={user.lastName}
          id="last-name"
          placeholder="Last Name"
          {...register("lastName", { required: false })}
        />

        <label htmlFor="username">Username</label>
        <input
          type="text"
          autoCapitalize="off"
          autoComplete="off"
          id="username"
          name="username"
          placeholder="Username"
          defaultValue={user.username}
          aria-label="Username"
          {...register("username", { required: true })}
        />
        {errors.username && <span>Username is required</span>}

        <label>Email</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          defaultValue={user.email}
          aria-label="Email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[+\w0-9._-]+@[\w0-9._-]+\.[a-zA-Z]{2,4}$/,
              message: "Invalid email address",
            },
          })}
        />
        {errors.email && <span>Email is required</span>}

        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default Profile;
