import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { useNoteActions } from "../services/api/note-service";

export const useNotes = ({ personId, noteId, filter, sort }) => {
  const noteActions = useNoteActions();
  const queryClient = useQueryClient();

  const {
    isLoading: isNotesLoading,
    error: notesError,
    data: notes,
  } = useQuery({
    queryKey: ["notes", personId, filter],
    queryFn: () => noteActions.retrievePersonNotes(personId, filter, sort),
    enabled: !noteId,
  });

  const {
    isLoading: isNoteLoading,
    error: noteError,
    data: note,
  } = useQuery({
    queryKey: ["notes", personId, noteId],
    queryFn: () => noteActions.retrieveNote(noteId),
    enabled: !!noteId,
  });

  const {
    isLoading: isNoteTypeOptionsLoading,
    error: noteTypeOptionsError,
    data: noteTypeOptions,
  } = useQuery({
    queryKey: ["noteTypeOptions"],
    queryFn: noteActions.retrieveNoteTypes,
  });

  const createNote = useMutation({
    mutationFn: (note) => noteActions.createNote(note),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["notes"] });
    },
  });

  const updateNote = useMutation({
    mutationFn: (note) => noteActions.updateNote(note),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notes"] });
    },
  });

  const deleteNote = useMutation({
    mutationFn: (id) => noteActions.deleteNote(id),
    onSuccess: () => {
      console.log("deleted note successfully");
      queryClient.invalidateQueries({ queryKey: ["notes"] });
    },
  });

  return {
    isNoteTypeOptionsLoading,
    noteTypeOptionsError,
    noteTypeOptions,
    isNotesLoading,
    notesError,
    notes,
    isNoteLoading,
    noteError,
    note,
    createNote,
    updateNote,
    deleteNote,
  };
};
