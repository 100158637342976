export const formatDate = (dateString, type = "short") => {
  if (!dateString || isNaN(new Date(dateString))) {
    return "";
  }

  if (type === "short") {
    return formatDateShort(new Date(dateString));
  } else {
    return formatDateLong(new Date(dateString));
  }
};

const formatDateLong = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  };
  return date.toLocaleDateString(undefined, options);
};

const formatDateShort = (date) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: "UTC",
  };
  return date.toLocaleDateString(undefined, options);
};
