import { useEffect, useRef } from "react";
import Todo from "./todo";
import styles from "./todos.module.scss";

const Todos = ({ todos, onEdit, onDelete }) => {
  const endOfList = useRef(null);

  useEffect(() => {
    endOfList.current.scrollIntoView({ behavior: "smooth" });
  }, [todos]);

  return (
    <ul className={styles.todos}>
      {todos.map((todo) => (
        <li key={todo.id} data-testid={`todo-${todo.id}`}>
          <Todo key={todo.id} todo={todo} onEdit={onEdit} onDelete={onDelete} />
        </li>
      ))}
      <div ref={endOfList} />
    </ul>
  );
};

export default Todos;
