import React, { useEffect, useState, useRef } from "react";
import { FaChevronDown } from "react-icons/fa";
import styles from "./account-menu.module.scss";

const AccountMenu = ({ userName, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={styles.accountMenu}
      ref={menuRef}
      data-testid="account-menu"
    >
      <div
        className={styles.accountName}
        onClick={toggleDropdown}
        data-testid="account-menu-dropdown"
      >
        {userName}
        <span className={`${styles.chevron} ${isOpen ? styles.open : ""}`}>
          <FaChevronDown />
        </span>
      </div>
      {isOpen && <div className={styles.dropdown}>{children}</div>}
    </div>
  );
};

export default AccountMenu;
