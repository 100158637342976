import { atom } from "recoil";

export const notesAtom = atom({
  key: "notes",
  default: [],
});

export const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("loggedInUser")),
});

export const currentPersonIDAtom = atom({
  key: "currentPersonID",
  default: localStorage.getItem("selectedPerson"),
});

export const currentTeamIDAtom = atom({
  key: "currentTeamID",
  default: localStorage.getItem("selectedTeam"),
});

export const isOnboardingAtom = atom({
  key: "isOnboarding",
  default: false,
});

export const onboardingAtom = atom({
  key: "onboarding",
  default: {},
});
