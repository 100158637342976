import React, { useState, useEffect } from "react";
import Messages from "./messages";
import MessageCreate from "./message-create";
import { useAssistant } from "../../hooks/use-assistants";
import styles from "./assistant.module.scss";

const Assistant = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    isMessagesLoading,
    assistantMessages,
    messagesError,
    askQuestion,
    updateAssistant,
  } = useAssistant();

  useEffect(() => {
    // Update the assistant with the latest data.
    updateAssistant.mutateAsync();
  }, []);

  useEffect(() => {
    if (assistantMessages) {
      const newMessages = createMessages(assistantMessages);
      setMessages([...newMessages].sort((a, b) => a.createdAt - b.createdAt));
    }
  }, [assistantMessages]);

  const createMessages = (assistantMessages) => {
    return assistantMessages.map((message) => ({
      id: message.id,
      type: message.role === "assistant" ? "answer" : "question",
      text: message.content,
      createdAt: message.created_at,
    }));
  };

  const onNewQuestion = (question) => {
    setLoading(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: "msg_new", type: "question", text: question, createdAt: -1 },
    ]);
    askQuestion.mutateAsync({ question }).then((response) => {
      const newMessages = createMessages(response);

      setMessages([...newMessages].sort((a, b) => a.createdAt - b.createdAt));
      setLoading(false);
    });
  };

  if (isMessagesLoading) {
    return <div data-testid="loading">Loading...</div>;
  }

  if (messagesError) {
    return <div data-testid="error">Error: {messagesError.message}</div>;
  }

  return (
    <div className={styles.assistant} data-testid="assistant">
      <div className={styles.messagesContainer}>
        <Messages messages={messages} />
      </div>
      <div className={styles.messageCreateContainer}>
        <MessageCreate
          loading={loading}
          onCreate={(question) => {
            onNewQuestion(question);
          }}
          data-testid="message-create"
        />
      </div>
    </div>
  );
};

export default Assistant;
