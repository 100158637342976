import "./css/pico-theme.css";
import "./css/index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import App from "./App";
import Warden from "./components/warden";
import Admin from "./pages/admin";
import Login from "./pages/login";
import Register from "./pages/register";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";
import Teams from "./components/teams/teams";
import TeamCreate from "./components/teams/team-create";
import TeamEdit from "./components/teams/team-edit";
import TeamJoin from "./components/teams/team-join";
import Persons from "./components/persons/persons";
import PersonCreate from "./components/persons/person-create";
import PersonEdit from "./components/persons/person-edit";
import CareerLadder from "./components/ladder/career-ladder";
import Profile from "./components/profile/profile";
import Dashboard from "./components/dashboard/dashboard";
import Home from "./pages/home";
import TryThings from "./components/experimental/try-things";
import ErrorMessage from "./common/error-message";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Warden />,
    errorElement: <ErrorMessage />,
  },
  {
    path: "/home",
    element: <App />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "teams",
        element: <Teams />,
      },
      {
        path: "teams/create",
        element: <TeamCreate />,
      },
      {
        path: "teams/:teamId/edit",
        element: <TeamEdit />,
      },
      {
        path: "teams/join",
        element: <TeamJoin />,
      },
      {
        path: "persons",
        element: <Persons />,
      },
      {
        path: "persons/create",
        element: <PersonCreate />,
      },
      {
        path: "persons/:personId/edit",
        element: <PersonEdit />,
      },
      {
        path: "ladders",
        element: <CareerLadder />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      { path: "main", element: <Home /> },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:code/:email",
    element: <ResetPassword />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/try",
    element: <TryThings />,
  },
]);

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>
);
