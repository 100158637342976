import { useState, useEffect } from "react";
import styles from "./progress.module.scss";

const Progress = () => {
  const [progress, setProgress] = useState(0);
  const [alternate, setAlternate] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 1
      );
    }, 200);

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, []);

  return (
    <div data-testid="progress">
      <svg
        width="250"
        height="250"
        viewBox="0 0 250 250"
        className={styles.circular_progress}
        style={{ "--progress": progress }}
      >
        <circle className={styles.bg}></circle>
        <circle className={styles.fg}></circle>
      </svg>
    </div>
  );
};

export default Progress;
