import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { usePersons } from "../../hooks/use-persons";
import { useTeams } from "../../hooks/use-teams";

const PersonCreate = () => {
  const [teamId, setTeamId] = useState("");

  const { isTeamsLoading, teams } = useTeams({});
  const { createPerson } = usePersons({});

  useEffect(() => {
    if (!isTeamsLoading) {
      if (teams.length > 0) {
        setTeamId(teams[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const email = watch("email");

  const onSubmit = async (data) => {
    createPerson.mutate({ firstName, lastName, email, teamId });
    navigate("/home/persons");
  };

  if (isTeamsLoading) {
    return <article aria-busy="true" data-testid="loading-indicator"></article>;
  }

  return (
    <div className="container" data-testid="person-create">
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
      >
        <div>
          <label htmlFor="first-name">
            First
            <input
              {...register("firstName", {
                required: "First name is required",
              })}
              className={errors?.firstName?.message ? "error" : "no-error"}
              id="first-name"
              placeholder="First Name"
            />
          </label>
          <p className="error">{errors.firstName?.message}</p>

          <label htmlFor="last-name">
            Last
            <input
              {...register("lastName", {
                required: "Last name is required",
              })}
              className={errors?.lastName?.message ? "error" : "no-error"}
              id="last-name"
              placeholder="Last Name"
            />
          </label>
          <p className="error">{errors.lastName?.message}</p>

          <label htmlFor="email">
            Email
            <input
              {...register("email", {
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
              className={errors?.email?.message ? "error" : "no-error"}
              id="email"
              placeholder="Email"
            />
          </label>
          <p className="error">{errors.email?.message}</p>

          <select
            onChange={(e) => {
              console.log("team selection changed");
              setTeamId(e.target.value);
            }}
          >
            {teams.map((team) => {
              return (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              );
            })}
          </select>
        </div>

        <input type="submit" value="Save" data-testid="submit-button" />
      </form>
      <Link onClick={() => navigate(-1)}>Cancel</Link>
    </div>
  );
};

export default PersonCreate;
