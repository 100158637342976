import { API_URL } from "../../constants";
import axiosInstance from "./api";

export { useAccessTypeActions };

function useAccessTypeActions() {
  const retrieveAccessTypes = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/access_types`);
      return response.data;
    } catch (error) {
      console.error("Error retrieving access types", error);
      throw error;
    }
  };

  return {
    retrieveAccessTypes,
  };
}
