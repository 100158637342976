import { useState, useEffect, useRef } from "react";
import { TbProgress, TbProgressCheck } from "react-icons/tb";
import styles from "./action.module.scss";

const Action = ({
  step,
  title,
  action,
  start,
  completed,
  stepComplete,
  minExecutionTime = 2000,
}) => {
  const [message] = useState(title);
  const actionExecuted = useRef(false);

  useEffect(() => {
    if (start === true && !actionExecuted.current) {
      actionExecuted.current = true;

      const timedPromise = new Promise((resolve) => {
        const timer = setTimeout(() => {
          resolve(timer);
        }, minExecutionTime);
      });

      const actionPromise = new Promise((resolve) => {
        action().then(() => {
          resolve(null);
        });
      });

      Promise.all([actionPromise, timedPromise]).then((timers) => {
        console.log(`timers: ${timers}`);
        timers.map((timer) => {
          if (timer !== null) {
            console.log(`clearing timer ${timer}`);
            clearTimeout(timer);
          }
        });
        console.log(
          `Step ${step} is complete! calling stepComplete: ${stepComplete}`
        );
        stepComplete(step);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  const renderProgressIcon = () => {
    if (completed) {
      return (
        <TbProgressCheck
          size={32}
          className={`${styles.progress} ${styles.complete}`}
          data-testid="progress-check"
        />
      );
    } else {
      return (
        <TbProgress
          size={32}
          className={`${styles.progress} ${start ? styles.active : ""}`}
        />
      );
    }
  };

  return (
    <div className={`${styles.action} ${start ? styles.active : ""}`}>
      {renderProgressIcon()}
      {message}
    </div>
  );
};

export default Action;
